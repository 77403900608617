import { Component } from '@angular/core';

@Component({
  selector: 'app-grid-placeholder',
  standalone: true,
  imports: [],
  templateUrl: './grid-placeholder.component.html',
  styleUrl: './grid-placeholder.component.scss'
})
export class GridPlaceholderComponent {

  items: number[] = [1,2,3,4,5];

}
