export interface IIntermediateFilter {
    id: number;
    label: string;
    photo: string;
    type: string;
    subfilter: string;
    uuid: string;
}

export class IntermediateFilter implements IIntermediateFilter {
    constructor(
        public id: number = 0,
        public label: string = '',
        public photo: string = '',
        public type: string = '',
        public subfilter: string = '',
        public uuid: string = ''
    ) {}
}