import { Component } from '@angular/core';

@Component({
  selector: 'sweetness-icon',
  standalone: true,
  imports: [],
  templateUrl: './sweetness.component.html',
  styleUrl: './sweetness.component.scss'
})
export class SweetnessComponent {

}
