import { Injectable, inject } from '@angular/core';
import { ApiService } from './api.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class UuidResolverService {

  private api = inject(ApiService);
  private local = inject(LocalService);

  constructor(
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    let uuid = route.params['uuid'];
    this.local.setUuid(uuid);
    this.api.setUuid(uuid);
    this.api.setCountries();
    this.local.setFilters();

    return of(uuid);
  }
}

export interface IUuid {
	restaurant?: string;
}

export class Uuid implements IUuid {
	constructor(
		public restaurant?: string
	) { }
}