@defer(when spirit) {
    @if (spirit) {
        <section>
            <div class="title">
                <h5 (click)="navigate(spirit!)">{{ spirit | spiritTranslation:'name' }}</h5>
                <div class="fav" (click)="favorite(spirit!); $event.stopPropagation();">
                    <img src="assets/images/{{spirit.fav ? 'fav' : 'no-fav'}}.png"/>
                </div>
            </div>
    
            <small>{{ spirit | spiritTranslation:'subname' }}</small>
    
            <small>
                <em>
                    @if (spirit.catalogSpirit!.type) {
                        {{ spirit.catalogSpirit!.type | catalogTranslation }}
                    }
                    @if (spirit.catalogSpirit.type && spirit.catalogSpirit.region) {
                        -
                    }
                    @if (spirit.catalogSpirit!.region) {
                        {{ spirit.catalogSpirit!.region | catalogTranslation }}
                    }
                </em>
            </small>
            
            <div class="prices">
                @for(price of spirit.priceVariants; track price.uuid) {
                    <app-price [price]="price" [menu]="menu"></app-price>
                }
            </div>
        </section>
    }
}