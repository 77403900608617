import { Component } from '@angular/core';

@Component({
  selector: 'cask-number-icon',
  standalone: true,
  imports: [],
  templateUrl: './cask-number.component.html',
  styleUrl: './cask-number.component.scss'
})
export class CaskNumberIcon {

}
