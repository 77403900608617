@switch (section) {
    @case (SECTIONS.MENU) {
        <span>{{ 'menu.title' | translate }}</span>
    }
    @case (SECTIONS.FAVORITES) {
        <span>{{ (menu | menuTranslation:SECTIONS.FAVORITES) ?? 'menu.favorites' | translate }}</span>
    }
    @case (SECTIONS.RECOMMENDED) {
        <span>{{ (menu | menuTranslation:SECTIONS.RECOMMENDED) ?? 'menu.highlight' | translate }}</span>
    }
    @case (SECTIONS.SELECTION) {
        <span>{{ (menu | menuTranslation:SECTIONS.SELECTION) ?? 'menu.selection' | translate }}</span>
    }
    @default { <span></span> }
}