@if (anySelectedFilter()) {
    <div class="selected-filters">
        <button mat-flat-button (click)="deleteAllSelectedFilters()">
            <span>Borrar filtros</span>
            <mat-icon iconPositionEnd>close</mat-icon>
        </button>
        
        @for(keypar of selectedFilters()|keyvalue; track keypar.key) {

            @if (keypar.key != 'category') {

                @for(filter of keypar.value; track filter.uuid) {
    
                    <button mat-stroked-button class="text-white border-white" (click)="deleteSelectedFilter(keypar.key, filter.uuid)">
                        <app-data-icon class="icon" [type]="keypar.key"></app-data-icon>
                        <span>{{ filter.name }}</span>
                        <mat-icon iconPositionEnd>close</mat-icon>
                    </button>
    
                }

            }

        }
    </div>
}