<main>
    <section class="swiper">
        @for (item of items; track $index) {
            <div class="item placeholder-wave placeholder-{{$index}}">
                <div class="placeholder"></div>
            </div>
        }
    </section>
</main>
   
<footer>
    <section class="placeholder-wave">
        <h5 class="placeholder"></h5>
        <small class="placeholder"></small>
        <div class="prices">
            <span class="placeholder"></span>
            <span class="placeholder"></span>
        </div>
    </section>
</footer>