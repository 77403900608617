export class GlobalConstants {

    public static SECTIONS = {
        MENU: 'menu',
        FAVORITES: 'favorites',
        RECOMMENDED: 'recommended',
        SELECTION: 'selection'
    };

    public static VIEWS = {
        GRID: 'grid',
        CAROUSEL: 'carousel',
        TRADITIONAL: 'grouped'
    };

    public static ENTITY_FILTERS = [
        'body',
        'caskNumber',
        'chillFilter',
        'colouring',
        'limitedEdition',
        'noAdds',
        'numberOfBottles',
        'richness',
        'smoke'
    ];

    public static RELATION_FILTERS = [
        'age',
        'bottler',
        'bottlingDate',
        'bottlingStatus',
        'brand',
        'capacity',
        'caskType',
        'category',
        'certification',
        'character',
        'country',
        'flavour',
        'foodPairings',
        'grapes',
        'ingType',
        'region',
        'series',
        'style',
        'sustainability',
        'type',
        'vintage',
        'volume'
    ];
}