import { Injectable, inject, signal } from '@angular/core';
import { ApiService } from './api.service';
import { Spirit } from '../models/spirit.model';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  private uuid = signal('');
  private api = inject(ApiService);

  constructor() {}

  public setUuid(uuid: string) {
    this.uuid.update(() => uuid);
  }

  public getUuid() {
    return this.uuid();
  }

  public saveData(key: string, value: string) {
    localStorage.setItem(this.uuid() + '_' + key, value);
  }

  public getData(key: string) {
    return localStorage.getItem(this.uuid() + '_' + key)
  }

  public removeData(key: string) {
    localStorage.removeItem(this.uuid() + '_' + key);
  }

  public clearData() {
    localStorage.clear();
  }

  public setDataWithExpiration(key: string, value: string, days: number, minutes?: number) {
    const now = new Date()
    let diff = 0;

    if (days) {
      diff = days * 24 * 60 * 60 * 1000
    } else if (minutes) {
      diff = minutes * 60 * 1000
    }

    const item = {
      value: value,
      expiry: now.getTime() + diff
    }
    
    localStorage.setItem(this.uuid() + '_' + key, JSON.stringify(item))
  }

  public getDataWithExpiration(key: string) {
    const itemStr = localStorage.getItem(this.uuid() + '_' + key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      this.removeData(this.uuid() + '_' + key);
      return null;
    }
    return item.value;
  }

  public setFilters() {
    const selectedFilters = this.getData('selectedFilters');
    if (selectedFilters) {
      this.api.setSelectedFilters(JSON.parse(selectedFilters));
    }
  }

  public manageFavorites(spirit: Spirit) {
    const localFavorites = this.getData('favorites');
    const favorites = localFavorites ? JSON.parse(localFavorites) : {};

    const selectedFilters = this.getData('selectedFilters');
    const currentCategory = JSON.parse(selectedFilters ?? '').category[0].uuid;

    const categoryFavorites = favorites[currentCategory];
    if (categoryFavorites) {
      const index = categoryFavorites.findIndex((favorite: Spirit) => favorite.uuid == spirit.uuid);

      if (index != -1) {
        categoryFavorites.splice(index, 1);
      } else {
        categoryFavorites.push(spirit);
      }

    } else {

      favorites[currentCategory] = [spirit];

    }

    this.saveData('favorites', JSON.stringify(favorites));
    this.updateSpiritsFavorites(spirit);
  }

  updateSpiritsFavorites(updatedSpirit: Spirit) {
    const spirits = this.getDataWithExpiration('spirits');
    if (spirits) {
      const data: Spirit[] = JSON.parse(spirits);
      const spirit = data.find(x => x.uuid == updatedSpirit.uuid);
      if (spirit) {
        spirit.fav = updatedSpirit.fav;
        this.setDataWithExpiration('spirits', JSON.stringify(data), 0, 1);
      }
    }
  }
}
