@switch (type) {
    @case ('age') {
        <age-icon class="icon"></age-icon>
    }
    @case ('body') {
        <body-icon class="icon"></body-icon>
    }
    @case ('bottler') {
        <bottler-icon class="icon"></bottler-icon>
    }
    @case ('bottlingDate') {
        <bottling-date-icon class="icon"></bottling-date-icon>
    }
    @case ('bottlingStatus') {
        <bottling-status-icon class="icon"></bottling-status-icon>
    }
    @case ('brand') {
        <brand-icon class="icon"></brand-icon>
    }
    @case ('capacity') {
        <capacity-icon class="icon"></capacity-icon>
    }
    @case ('caskNumber') {
        <cask-number-icon class="icon"></cask-number-icon>
    }
    @case ('caskType') {
        <cask-type-icon class="icon"></cask-type-icon>
    }
    @case ('category') {
        <category-icon class="icon"></category-icon>
    }
    @case ('certification') {
        <certification-icon class="icon"></certification-icon>
    }
    @case ('characters') {
        <characters-icon class="icon"></characters-icon>
    }
    @case ('chillFilter') {
        <chill-filter-icon class="icon"></chill-filter-icon>
    }
    @case ('colouring') {
        <colouring-icon class="icon"></colouring-icon>
    }
    @case ('country') {
        <country-icon class="icon"></country-icon>
    }
    @case ('description') {
        <description-icon class="icon"></description-icon>
    }
    @case ('flavour') {
        <flavour-icon class="icon"></flavour-icon>
    }
    @case ('foodPairings') {
        <food-pairings-icon class="icon"></food-pairings-icon>
    }
    @case ('grapes') {
        <grapes-icon class="icon"></grapes-icon>
    }
    @case ('ingType') {
        <ing-type-icon class="icon"></ing-type-icon>
    }
    @case ('limitedEdition') {
        <limited-edition-icon class="icon"></limited-edition-icon>
    }
    @case ('noAdds') {
        <no-adds-icon class="icon"></no-adds-icon>
    }
    @case ('numberOfBottles') {
        <number-of-bottles-icon class="icon"></number-of-bottles-icon>
    }
    @case ('price') {
        <price-icon class="icon"></price-icon>
    }
    @case ('region') {
        <region-icon class="icon"></region-icon>
    }
    @case ('richness') {
        <richness-icon class="icon"></richness-icon>
    }
    @case ('series') {
        <series-icon class="icon"></series-icon>
    }
    @case ('smoke') {
        <smoke-icon class="icon"></smoke-icon>
    }
    @case ('style') {
        <style-icon class="icon"></style-icon>
    }
    @case ('sustainability') {
        <sustainability-icon class="icon"></sustainability-icon>
    }
    @case ('sweetness') {
        <sweetness-icon class="icon"></sweetness-icon>
    }
    @case ('tastingNotes') {
        <tasting-notes-icon class="icon"></tasting-notes-icon>
    }
    @case ('type') {
        <type-icon class="icon"></type-icon>
    }
    @case ('vintage') {
        <vintage-icon class="icon"></vintage-icon>
    }
    @case ('volume') {
        <volume-icon class="icon"></volume-icon>
    }
    @case ('elaboration') {
        <elaboration-icon class="icon"></elaboration-icon>
    }
    @case ('ingredients') {
        <ingredients-icon class="icon"></ingredients-icon>
    }
    @default { }
}