import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LandscapeComponent } from './shared/components/landscape/landscape.component';
import { TranslationHelperService } from './core/services/translation-helper.service';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LandscapeComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  title = 'Spiritsrim';

  constructor(
    private translationHelperService: TranslationHelperService,
    private platform: Platform
  ) {
    if (this.platform.is('hybrid')) {
      this.platform.ready().then(async () => {
        setTimeout(() => {
          SplashScreen.hide({
            fadeOutDuration: 500,
          });
        }, 2000);
      });
    }
  }
}
