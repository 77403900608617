import { Routes } from '@angular/router';
import { ShortcodeComponent } from './features/shortcode/shortcode.component';
import { UuidResolverService } from './core/services/uuid-resolver.service';
import { HomeComponent } from './features/home/home.component';
import { MenuComponent } from './features/menu/layout/menu.component';
import { SpiritDetailComponent } from './features/menu/spirit-detail/spirit-detail.component';
import { CategoriesComponent } from './features/categories/layout/categories.component';
import { IntermediateFiltersComponent } from './features/intermediate-filters/layout/intermediate-filters.component';
import { CategorySelectedService } from './core/services/category-selected.service';

export const routes: Routes = [
    { 
        path: 'shortcode',
        component: ShortcodeComponent 
    },
    { 
        path: ':uuid',
        resolve: { uuid: UuidResolverService },
        component: HomeComponent
    },
    { 
        path: ':uuid/categories',
        resolve: { uuid: UuidResolverService },
        component: CategoriesComponent
    },
    { 
        path: ':uuid/intermediate-filters',
        resolve: { uuid: UuidResolverService, CategorySelectedService },
        component: IntermediateFiltersComponent
    },
    { 
        path: ':uuid/menu',
        resolve: { uuid: UuidResolverService, CategorySelectedService },
        component: MenuComponent,
        children: [
          { path: ':spiritSlugname', component: SpiritDetailComponent }
        ]
    },
    { path: '', redirectTo: '/shortcode', pathMatch: 'full' }
];
