<main>
    <header>
        @defer (when menu) {
            <section class="first-row">
                <div class="left">
                    <button mat-icon-button (click)="goHome()"><mat-icon>keyboard_arrow_left</mat-icon></button>
                    <span>{{ 'menu.spirit_type' | translate }}</span>
                </div>
                <div class="center">
                    <app-section-switch [menu]="menu" [section]="section()" (update)="changeSection($event)"></app-section-switch>
                </div>
            </section>
            <section class="second-row">
                <app-menu-title [menu]="menu" [section]="section()"></app-menu-title>
                <span class="spirits-total">
                    {{ 'menu.spirits_total' | translate:{'count': totalSpirits} }}
                </span>
                <app-selected-filters #appSelectedFilters (update)="applyFilters()"></app-selected-filters>
            </section>
            <section class="third-row">
                <app-search [search]="search()" [section]="section()" (update)="changeSearch($event)"></app-search>
                <app-view-switch [menu]="menu" [view]="view()" (update)="changeView($event)"></app-view-switch>
            </section>
        }
    </header>

    <div id="spirits">
        @if (view() == VIEWS.GRID) {
            @defer(when spirits()) {
                @if (spirits() && spirits()!.length > 0) {
                    <app-grid [uuid]="slugname" [spirits]="spirits()" [menu]="menu" [section]="section()"
                        infinite-scroll
                        [infiniteScrollThrottle]="10"
                        [scrollWindow]="false"
                        (scrolled)="getSpirits()"
                    ></app-grid>
                }
                @if (spirits() && spirits()!.length == 0) {
                    <app-spirits-empty></app-spirits-empty>
                }
            }@placeholder {
                <app-grid-placeholder></app-grid-placeholder>
            }
        }
    
        @if (view() == VIEWS.CAROUSEL) {
            @defer(when spirits()) {
                @if (spirits() && spirits()!.length > 0) {
                    <app-carousel #appCarousel [uuid]="slugname" [spirits]="spirits()" [menu]="menu" [section]="section()" (getSpirits)="getSpirits()"></app-carousel>
                }
                @if (spirits() && spirits()!.length == 0) {
                    <app-spirits-empty></app-spirits-empty>
                }
            } @placeholder {
                <app-carousel-placeholder></app-carousel-placeholder>
            }
        }
    
        @if (view() == VIEWS.TRADITIONAL) {
            @defer(when spirits()) {
                @if (spirits() && spirits()!.length > 0) {
                    <app-grouped [uuid]="slugname" [spirits]="spirits()" [menu]="menu" [section]="section()"
                        infinite-scroll
                        [infiniteScrollThrottle]="10"
                        [scrollWindow]="false"
                        (scrolled)="getSpirits()"
                    ></app-grouped>
                }
                @if (spirits() && spirits()!.length == 0) {
                    <app-spirits-empty></app-spirits-empty>
                }
            } @placeholder {
                <app-grouped-placeholder></app-grouped-placeholder>
            }
        }
    </div>

    <footer>
        @if (section() == SECTIONS.MENU) {
            <app-footer-desktop-filters #appDesktopFilters (update)="applyFilters()"></app-footer-desktop-filters>
            <app-footer-mobile-filters-button (update)="applyFilters()"></app-footer-mobile-filters-button>

            <section class="mobile-selected-filters">
                <app-selected-filters #appSelectedFiltersMobile (update)="applyFilters()"></app-selected-filters>
            </section>
        }
    </footer>
</main>

<router-outlet></router-outlet>