<article>
    <figure (click)="navigate(spirit)">
        <img [src]="spirit.catalogSpirit.image | spiritImage"/>
        <div class="fav" [class.fav-active]="spirit.fav" (click)="favorite(spirit); $event.stopPropagation();">
            <img src="assets/images/{{spirit.fav ? 'fav' : 'no-fav'}}.png"/>
        </div>
    </figure>
    <div class="info" (click)="navigate(spirit)">
        <h5>{{ spirit | spiritTranslation:'name' }}</h5>

        <small>{{ spirit | spiritTranslation:'subname' }}</small>

        <small>
            <em>
                @if (spirit.catalogSpirit.type) {
                    {{ spirit.catalogSpirit.type | catalogTranslation }}
                }
                @if (spirit.catalogSpirit.type && spirit.catalogSpirit.region) {
                    -
                }
                @if (spirit.catalogSpirit.region) {
                    {{ spirit.catalogSpirit.region | catalogTranslation }}
                }
            </em>
        </small>

        <div class="prices">
            @for (price of spirit.priceVariants; track price.uuid) {
                <app-price [price]="price" [size]="'small'" [menu]="menu"></app-price>
            }
        </div>
    </div>
</article>