import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  standalone: true,
  name: 'spiritImage'
})
export class SpiritImagePipe implements PipeTransform {

    private url: string = environment.api + '/images/catalog/spirits/';

    transform(image: string | undefined): string {
      if (!image) {
        return '';
      }
      return this.url + image;
    }
}