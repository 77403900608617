import { Component } from '@angular/core';

@Component({
  selector: 'app-grouped-placeholder',
  standalone: true,
  imports: [],
  templateUrl: './grouped-placeholder.component.html',
  styleUrl: './grouped-placeholder.component.scss'
})
export class GroupedPlaceholderComponent {

  items: number[] = [1,2,3,4,5];
  
}
