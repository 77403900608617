import { Component } from '@angular/core';

@Component({
  selector: 'brand-icon',
  standalone: true,
  imports: [],
  templateUrl: './brand.component.html',
  styleUrl: './brand.component.scss'
})
export class BrandIcon {}
