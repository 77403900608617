import { Component } from '@angular/core';

@Component({
  selector: 'ingredients-icon',
  standalone: true,
  imports: [],
  templateUrl: './ingredients.component.html',
  styleUrl: './ingredients.component.scss'
})
export class IngredientsIcon {

}
