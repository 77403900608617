<section>
    <header class="placeholder-wave prices">
        <small class="placeholder"></small>
        /
        <small class="placeholder"></small>
    </header>

    @for (item of items; track $index) {
        <article class="placeholder-wave placeholder-{{$index}}">
            <div class="spirit">
                <div class="fav">
                    <span class="placeholder"></span>
                </div>
                <div class="info">
                    <h6 class="placeholder"></h6>
                    <small class="placeholder"></small>
                </div>
            </div>
            <div class="prices">
                <small class="placeholder"></small>
                /
                <small class="placeholder"></small>
            </div>
        </article>
    }
</section>