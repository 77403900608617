import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxSliderModule, Options as SliderOptions } from '@angular-slider/ngx-slider';

const materialModules = [
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule
];

@Component({
  selector: 'app-price-variant',
  standalone: true,
  imports: [NgxSliderModule, ...materialModules],
  templateUrl: './price-variant.component.html',
  styleUrl: './price-variant.component.scss'
})
export class PriceVariantComponent {

  minPrice: number = 10;
  maxPrice: number = 150;

  pricesOptions: SliderOptions = {
    floor: 0,
    ceil: 200
  };

}
