import { Component } from '@angular/core';

@Component({
  selector: 'cask-type-icon',
  standalone: true,
  imports: [],
  templateUrl: './cask-type.component.html',
  styleUrl: './cask-type.component.scss'
})
export class CaskTypeIcon {

}
