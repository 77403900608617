import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Spirit } from '../../../../core/models/spirit.model';
import { PriceComponent } from '../../../../shared/components/price/price.component';
import { LocalService } from '../../../../core/services/local.service';
import { SpiritTranslationPipe } from '../../../../core/pipes/spiritTranslation';
import { CatalogTranslationPipe } from '../../../../core/pipes/catalogTranslation';

const pipes = [
  SpiritTranslationPipe,
  CatalogTranslationPipe
]

@Component({
  selector: 'app-carousel-active-item',
  standalone: true,
  imports: [PriceComponent, ...pipes],
  templateUrl: './carousel-active-item.component.html',
  styleUrl: './carousel-active-item.component.scss'
})
export class CarouselActiveItemComponent {

  @Input() spirit!: Spirit | null;
  @Input() menu: any;
  @Output() goToDetail = new EventEmitter<Spirit>();

  private local = inject(LocalService);

  navigate(spirit: Spirit) {
    this.goToDetail.emit(spirit);
  }

  favorite(spirit: Spirit) {
    spirit.fav = !spirit.fav;
    this.local.manageFavorites(spirit);
  }
}
