<div class="section-switch">
    <div class="section-switch-option" [class.selected-option]="section == SECTIONS.MENU" (click)="changeSection(SECTIONS.MENU);">
        <span>{{ 'menu.menu' | translate }}</span>
    </div>
    @if(hasRecommended && showRecommended) {
        <div class="section-switch-option" [class.selected-option]="section == SECTIONS.RECOMMENDED" (click)="changeSection(SECTIONS.RECOMMENDED);">
            <span>{{ (menu | menuTranslation:SECTIONS.RECOMMENDED) ?? 'menu.highlight' | translate }}</span>
        </div>
    }
    @if(showSelection) {
        <div class="section-switch-option" [class.selected-option]="section == SECTIONS.SELECTION" (click)="changeSection(SECTIONS.SELECTION);">
            <span>{{ (menu | menuTranslation:SECTIONS.SELECTION) ?? 'menu.selection' | translate }}</span>
        </div>
    }
    <div class="section-switch-option" [class.selected-option]="section == SECTIONS.FAVORITES" (click)="changeSection(SECTIONS.FAVORITES);">
        <span>{{ (menu | menuTranslation:SECTIONS.FAVORITES) ?? 'menu.favorites' | translate }}</span>
    </div>
</div>