<div id="spirit-detail">
    <header>
        <div class="back">
            <button mat-icon-button (click)="goBack()"><mat-icon>keyboard_arrow_left</mat-icon></button>
            @if(spirit()) {
                <span>{{ 'detail.back_button' | translate }} {{ spirit()?.catalogSpirit?.category | catalogTranslation }}</span>
            }
        </div>
        <p>{{ 'detail.title' | translate }}</p>
    </header>
    <main>
        @if(spirit()) {
            <section class="img">
                <img [src]="spirit()?.catalogSpirit!.image | spiritImage" alt="">
            </section>
            <section class="info">
                <div class="row">
                    <h1>{{ spirit() | spiritTranslation:'name' }}</h1>
                    <div class="fav" [class.fav-active]="spirit()?.fav" (click)="favorite(spirit()!); $event.stopPropagation();">
                        <img src="assets/images/{{spirit()?.fav ? 'fav' : 'no-fav'}}.png"/>
                    </div>
                </div>

                <h4>{{ spirit() | spiritTranslation:'subname' }}</h4>

                <h6 class="text-light">
                    {{ spirit()?.catalogSpirit!.type | catalogTranslation }}@if ( spirit()?.catalogSpirit!.type && spirit()?.catalogSpirit!.category ) {,}
                    {{ spirit()?.catalogSpirit!.category | catalogTranslation}}</h6>
                <h6 class="text-light">
                    {{ spirit()?.catalogSpirit!.region | catalogTranslation }} @if ( spirit()?.catalogSpirit!.region && spirit()?.catalogSpirit!.country ) {/}
                    {{ spirit()?.catalogSpirit!.country | catalogTranslation }}
                </h6>
                <div class="prices text-light">
                    @defer (when menu()) {
                        @for (price of spirit()?.priceVariants; track price.uuid) {
                            <app-price [price]="price" [menu]="menu()" [showName]=true></app-price>
                        }
                    }
                </div>
                <p class="text-light"><b>{{ 'detail.characteristics' | translate }}</b></p>

                @defer(when catalogSpirit()) {
                    <div class="characteristics">    
                             
                        <app-spirit-detail-characteristic
                            name="brand"
                            [data]="catalogSpirit()?.brand"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="description"
                            [data]="spirit()"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="age"
                            [data]="catalogSpirit()?.age"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="vintage"
                            [data]="catalogSpirit()?.vintage"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="volume"
                            [data]="catalogSpirit()?.volume"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="capacity"
                            [data]="catalogSpirit()?.capacity"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="tastingNotes"
                            [data]="spirit()"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="ingredients"
                            [data]="spirit()"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="elaboration"
                            [data]="spirit()"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="body"
                            [data]="catalogSpirit()?.body"
                            class="w-auto"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="richness"
                            [data]="catalogSpirit()?.richness"
                            class="w-auto"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="smoke"
                            [data]="catalogSpirit()?.smoke"
                            class="w-auto"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="sweetness"
                            [data]="catalogSpirit()?.sweetness"
                            class="w-auto"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="characters"
                            [data]="catalogSpirit()?.characters"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="foodPairings"
                            [data]="catalogSpirit()?.foodPairings"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="caskNumber"
                            [data]="catalogSpirit()?.caskNumber"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="caskType"
                            [data]="catalogSpirit()?.caskType"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="style"
                            [data]="catalogSpirit()?.style"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="flavour"
                            [data]="catalogSpirit()?.flavour"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="ingType"
                            [data]="catalogSpirit()?.ingType"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="grapes"
                            [data]="catalogSpirit()?.grapes"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="bottler"
                            [data]="catalogSpirit()?.bottler"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="bottlingStatus"
                            [data]="catalogSpirit()?.bottlingStatus"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="numberOfBottles"
                            [data]="catalogSpirit()?.numberOfBottles"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="limitedEdition"
                            [data]="catalogSpirit()?.limitedEdition"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="series"
                            [data]="catalogSpirit()?.series"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="bottlingDate"
                            [data]="catalogSpirit()?.bottlingDate"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="certification"
                            [data]="catalogSpirit()?.certification"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="sustainability"
                            [data]="catalogSpirit()?.sustainability"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="colouring"
                            [data]="catalogSpirit()?.colouring"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="chillFilter"
                            [data]="catalogSpirit()?.chillFilter"
                        ></app-spirit-detail-characteristic>

                        <app-spirit-detail-characteristic
                            name="noAdds"
                            [data]="catalogSpirit()?.noAdds"
                        ></app-spirit-detail-characteristic>

                    </div>
                }

            </section>
        }
    </main>
</div>