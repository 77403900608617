import { Component, Input, OnInit, WritableSignal, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DataIconComponent } from '../../../../shared/components/data-icon/data-icon.component';
import { CatalogTranslationPipe } from '../../../../core/pipes/catalogTranslation';
import { SpiritTranslationPipe } from '../../../../core/pipes/spiritTranslation';
import { LineBreakPipe } from '../../../../core/pipes/lineBreak';

const components = [
  DataIconComponent,
  CatalogTranslationPipe,
  SpiritTranslationPipe,
  LineBreakPipe
]

@Component({
  selector: 'app-spirit-detail-characteristic',
  standalone: true,
  imports: [TranslateModule, ...components],
  templateUrl: './spirit-detail-characteristic.component.html',
  styleUrl: './spirit-detail-characteristic.component.scss'
})
export class SpiritDetailCharacteristicComponent implements OnInit {

  @Input() name!: string;
  @Input() data!: any;

  key: WritableSignal<string> = signal('');
  value: WritableSignal<any> = signal(null);
  valueType: WritableSignal<string> = signal('');

  ngOnInit(): void {
    if (this.data) {
      this.key.set(this.name);
      this.value.set(this.data);
      this.valueType.set(this.getType(this.name, this.data));
    }
  }

  getType(key: string, variable: any) {
    if (Array.isArray(variable)) {
      return 'Array';
    } else if (['body', 'richness', 'smoke', 'sweetness'].includes(key)) {
      return 'Circles';
    } else if (['description', 'tastingNotes', 'ingredients', 'elaboration'].includes(key)) {
      return 'Spirit';
    } else if (variable === null) {
      return 'null';
    } else if (variable instanceof Date) {
      return 'Date';
    } else if (variable instanceof RegExp) {
      return 'RegExp';
    } else if (typeof variable === 'object') {
      return 'Object';
    } else if (typeof variable === 'string' && ['description', 'tastingNotes'].includes(key)) {
      return 'Textarea';
    } else {
      return typeof variable;
    }
  }
}
