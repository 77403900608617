import { Component, inject, signal } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LocalService } from '../../../core/services/local.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

const materialModules = [
  MatIconModule,
  MatDialogModule,
  MatButtonModule
]

@Component({
  selector: 'app-reset-storage',
  standalone: true,
  imports: [TranslateModule, ...materialModules],
  templateUrl: './reset-storage.component.html',
  styleUrl: './reset-storage.component.scss'
})
export class ResetStorageComponent {

  private local = inject(LocalService);

  filters = signal(false);
  view = signal(false);
  cart = signal(false);
  favorites = signal(false);

  constructor(
    private dialogRef: MatDialogRef<ResetStorageComponent>
  ) {}

  ngOnInit(): void {
    this.getLocal();
  }

  getLocal(): void {
    this.filters.set(this.local.getData('filters') != null);
    this.view.set(this.local.getData('view') != null);
    this.cart.set(this.local.getData('cart') != null);
    this.favorites.set(this.local.getData('favorites') != null);
  }

  close() {
    this.dialogRef.close();
  }

  reset() {
    this.local.removeData('cacheFilters');
    this.local.removeData('selectedMenu');
    this.local.removeData('filters');
    this.local.removeData('displaySelectedFilters');
    this.local.removeData('view');
    this.local.removeData('cart');
    this.local.removeData('favorites');
    this.close();
  }

}
