<section>
    <header>
        <small class="text-light">
            {{ menu | priceVariantTranslation:'copa' }} / {{ menu | priceVariantTranslation:'botella' }}
        </small>
    </header>

    @for (spirit of spirits; track spirit.uuid) {
        <app-grouped-item [spirit]="spirit" [menu]="menu" (goToDetail)="navigate($event)"></app-grouped-item>
    }
</section>