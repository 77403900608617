<div class="background">
    <img
        [src]="menu.user.activeModules.includes('backgroundImage') ? (menu.backgroundImage?.image | backgroundImage) : 'assets/images/fondo-carrusel.png'"
        onerror="this.onerror=null;this.src='assets/images/fondo-carrusel.png';"
    />
</div>

<main>
    <swiper
        [config]="swiperOptions"
        (slideChange)="onSlide($event)"
        class="swiper"
    >
        @for (spirit of spirits; track spirit.uuid) {
            <ng-template swiperSlide>
                <app-carousel-item [spirit]="spirit" [menu]="menu" (goToDetail)="navigate($event)"></app-carousel-item>
            </ng-template>
        }
    </swiper>
</main>
   
<footer>
    <app-carousel-active-item [spirit]="activeSpirit" [menu]="menu" (goToDetail)="navigate($event)"></app-carousel-active-item>
</footer>


