<section>
    @for (item of items; track $index) {
        <article class="placeholder-wave placeholder-{{$index}}">
            <figure class="placeholder">
                <img/>
            </figure>
            <div class="info">
                <h5 class="placeholder"></h5>
                <small class="placeholder"></small>
                <div class="prices">
                    <span class="placeholder"></span>
                    <span class="placeholder"></span>
                </div>
            </div>
        </article>
    }
</section>