@defer(when categoryFilters() && allFilters()) {

    @for (categoryFilter of categoryFilters(); track categoryFilter.uuid) {

        @if (allFilters()?.[categoryFilter?.filter?.name ?? ''] && allFilters()?.[categoryFilter!.filter!.name]!.length > 0) {

            <div class="filter" (click)="openFilter(categoryFilter)">
                <app-data-icon class="text-light icon" [type]="categoryFilter?.filter?.name"></app-data-icon>
                <span class="name">{{ categoryFilter | catalogTranslation }}</span>
    
                @if (categoryFilter?.selectedCount) {
                    <span class="count">{{ categoryFilter?.selectedCount ?? 0 }}</span>
                }@else {
                    <mat-icon>expand_more</mat-icon>
                }
            </div>

        }

    }

    <!-- @if (categoryFilters()!.regions && categoryFilters()!.regions.length > 0) {
        <div class="filter" (click)="openPriceVariantFilter()">
            <price-icon class="text-light icon"></price-icon>
            <span class="name">{{ 'filters.prices' | translate }}</span>
            <span class="count">2</span>
            <mat-icon>expand_more</mat-icon>
        </div>
    } -->
}@placeholder {
    <div class="filter placeholder-wave placeholder"></div>
    <div class="filter placeholder-wave placeholder"></div>
}