import { Component } from '@angular/core';

@Component({
  selector: 'vintage-icon',
  standalone: true,
  imports: [],
  templateUrl: './vintage.component.html',
  styleUrl: './vintage.component.scss'
})
export class VintageIcon {

}
