import { Component } from '@angular/core';

@Component({
  selector: 'certification-icon',
  standalone: true,
  imports: [],
  templateUrl: './certification.component.html',
  styleUrl: './certification.component.scss'
})
export class CertificationIcon {

}
