import { Component, EventEmitter, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MobileFiltersComponent } from '../mobile-filters/mobile-filters.component';
import { FilterComponent } from '../../filters/filter/filter.component';
import { ApiService } from '../../../../core/services/api.service';
import { CategoryFilter } from '../../../../core/models/categoryFilter.model';
import { Filters } from '../../../../core/models/filters.model';
import { TranslateModule } from '@ngx-translate/core';
const materialModules = [
  MatIconModule,
  MatButtonModule,
  MatBottomSheetModule
]

@Component({
  selector: 'app-footer-mobile-filters-button',
  standalone: true,
  imports: [...materialModules, TranslateModule],
  templateUrl: './mobile-filters-button.component.html',
  styleUrl: './mobile-filters-button.component.scss'
})
export class MobileFiltersButtonComponent implements OnInit {

  @Output() update = new EventEmitter();

  private api = inject(ApiService);

  categoryFilters: WritableSignal<CategoryFilter[]|null> = signal(null);
  allFilters: WritableSignal<Filters|null> = signal(null);

  constructor(
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.getCategoryFilters();
  }

  openFilters() {
    const bottomSheet = this._bottomSheet.open(MobileFiltersComponent, {
      data: { categoryFilters: this.categoryFilters(), allFilters: this.allFilters() },
      panelClass: 'mat-bottom-sheet-container-medium'
    });

    bottomSheet.afterDismissed().subscribe(
      data => {
        if (data) {
          this.openSelectedFilter(data);
        }
      }
    );
  }

  openSelectedFilter(categoryFilter: any) {
    const bottomSheetRef = this._bottomSheet!.open(FilterComponent, {
      data: { categoryFilter, allFilters: this.allFilters() },
      panelClass: 'mat-bottom-sheet-container-medium'
    });

    bottomSheetRef.afterDismissed().subscribe((filtersApplied) => {
      if (filtersApplied) {
        this.update.emit();
      }
    });
  }

  getCategoryFilters() {
    this.api.getCategoryFilters().subscribe(
      data => {
        this.categoryFilters.set(data);
        if (data.length > 0) {
          this.getAllFilterData(data[0].uuid);
        }
      }
    );
  }

  getAllFilterData(filterUuid: string) {
    this.api.getAllFilterData(filterUuid).subscribe(
      data => {
        this.allFilters.set(data);
      }
    );
  }
}
