import { Component } from '@angular/core';

@Component({
  selector: 'no-adds-icon',
  standalone: true,
  imports: [],
  templateUrl: './no-adds.component.html',
  styleUrl: './no-adds.component.scss'
})
export class NoAddsIcon {

}
