import { Component } from '@angular/core';

@Component({
  selector: 'bottler-icon',
  standalone: true,
  imports: [],
  templateUrl: './bottler.component.html',
  styleUrl: './bottler.component.scss'
})
export class BottlerIcon {

}
