<article [class.soldout]="soldout">
    <img [src]="spirit.catalogSpirit.image | spiritImage" (click)="navigate(spirit)"/>

    @if (soldout) {
        <span class="soldout-chip">Sin stock</span>
    }

    @if (new) {
        <div class="new background-primary">
            <span class="text-white">{{ menu.newsName ? menu.newsName : 'new' | translate }}</span>
        </div>
    }
</article>