import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { LocalService } from '../../../../core/services/local.service';
import { GlobalConstants } from '../../../../core/global-constants';

@Component({
  selector: 'app-view-switch',
  standalone: true,
  imports: [],
  templateUrl: './view-switch.component.html',
  styleUrl: './view-switch.component.scss'
})
export class ViewSwitchComponent {

  VIEWS = GlobalConstants.VIEWS;

  private local = inject(LocalService)
  
  @Input() view: string = '';
  @Input() menu: any;

  @Output() update = new EventEmitter<string>();

  hasGrouped = false;

  constructor() {}

  ngOnInit(): void {
    this.hasGrouped = this.menu.formats?.includes('grouped') ?? true;
  }

  changeView(view: string) {
    this.local.setDataWithExpiration('view', view, 0, 10);
    this.update.emit(view);
  }

}
