import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LanguageService } from '../services/language.service';

export const acceptLanguageInterceptor: HttpInterceptorFn = (req, next) => {
  const languageService = inject(LanguageService);

  const cloneReq = req.clone({
    setHeaders: {
      'Accept-Language': languageService.getCurrentLang()
    }
  });

  return next(cloneReq);
};
