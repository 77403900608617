<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6206_874)">
    <path d="M7.5601 18.541L14.7635 18.6953L20.0856 15.4549C19.9683 15.3296 19.8197 15.2337 19.6398 15.1671C19.4599 15.1005 19.2729 15.0915 19.0789 15.1401L14.1803 16.3672C13.7438 16.4765 13.3921 16.5474 13.1254 16.5799C12.8586 16.6124 12.5756 16.6145 12.2765 16.5864L9.83286 16.3996L9.89254 14.3744L12.0209 14.5371C12.316 14.5491 12.6555 14.5327 13.0395 14.4881C13.4235 14.4434 13.9813 14.3381 14.7128 14.172C14.6683 13.9942 14.5732 13.8376 14.4276 13.7022C14.2821 13.5669 14.1304 13.4932 13.9728 13.4811L7.77568 12.8171L6.22363 13.2059L7.5601 18.541ZM2.5904 23.3941L-0.0825195 12.7238L7.28969 10.877C7.40286 10.8487 7.51907 10.8324 7.63832 10.8283C7.75756 10.8242 7.86973 10.8262 7.97484 10.8342L14.2023 11.5165C14.7844 11.5768 15.3019 11.808 15.7548 12.21C16.2077 12.612 16.5071 13.104 16.6529 13.686L18.593 13.2C19.4013 12.9975 20.1552 13.0922 20.8547 13.4839C21.5542 13.8757 22.0133 14.5081 22.232 15.3811L22.475 16.3512L15.3223 20.7202L8.05823 20.5296L8.41057 21.9361L2.5904 23.3941ZM4.04447 20.968L5.98453 20.482L4.28358 13.6918L2.34352 14.1778L4.04447 20.968Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 20V21H21V20L17 18V17.3355L14.7598 18.6994L13.6488 18.6756L11 20ZM15 16.1651V15.9C13.8333 15.7101 12.1642 15.233 11.0712 14.4689L12.0172 14.5412C12.3123 14.5532 12.6518 14.5369 13.0358 14.4922C13.4198 14.4476 13.9776 14.3422 14.7092 14.1761C14.6806 14.062 14.6312 13.9566 14.561 13.86C15.097 13.9533 15.6212 14 16 14C16.9333 14 18.75 13.7167 19.45 13.15C20.15 12.5833 20.6167 11.8667 20.85 11H11.15C11.3833 11.8667 11.85 12.5833 12.55 13.15C12.6479 13.2292 12.7676 13.3029 12.9037 13.3711L9.80232 13.0388C9.26744 12.1385 9 11.1255 9 10L10 3H12L11 9H21L20 3H22L23 10C23 11.5 22.525 12.8 21.575 13.9C21.1281 14.4175 20.4064 14.8391 19.6181 15.1648C19.4437 15.1041 19.2628 15.0973 19.0753 15.1442L15 16.1651Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_6206_874">
    <rect width="24" height="24" fill="none"/>
    </clipPath>
    </defs>
    </svg>
    