import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Spirit } from '../../../../core/models/spirit.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '../../../../core/services/local.service';
import { SpiritTranslationPipe } from '../../../../core/pipes/spiritTranslation';
import { CatalogTranslationPipe } from '../../../../core/pipes/catalogTranslation';

const pipes = [
  SpiritTranslationPipe,
  CatalogTranslationPipe
]

@Component({
  selector: 'app-grouped-item',
  standalone: true,
  imports: [...pipes],
  templateUrl: './grouped-item.component.html',
  styleUrl: './grouped-item.component.scss'
})
export class GroupedItemComponent implements OnInit {

  @Input() spirit!: Spirit;
  @Input() menu: any;
  @Output() goToDetail = new EventEmitter<Spirit>();

  private local = inject(LocalService);

  copa: any;
  botella: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.copa = this.spirit.priceVariants.find(price => price.priceVariant.slugName == 'copa');
    this.botella = this.spirit.priceVariants.find(price => price.priceVariant.slugName == 'botella');
  }

  navigate(spirit: Spirit) {
    this.goToDetail.emit(spirit);
  }

  favorite(spirit: Spirit) {
    spirit.fav = !spirit.fav;
    this.local.manageFavorites(spirit);
  }
}
