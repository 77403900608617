<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6206_870)">
    <path d="M19.2844 10.5C19.9069 10.5 20.4994 10.665 21.0019 10.995C21.8569 11.55 22.3819 12.3975 22.5019 13.38C22.5844 14.3475 22.2469 15.3 21.5644 15.9825C21.0394 16.5 20.3719 16.83 19.6369 16.905C18.2044 17.055 16.9444 17.625 16.0069 18.5775C15.8494 18.7275 15.6769 18.75 15.5794 18.75C15.4894 18.75 15.3169 18.7275 15.1594 18.5775L14.4169 17.835C14.2819 17.7 14.2519 17.5425 14.2519 17.4375C14.2519 17.25 14.3344 17.085 14.4919 16.9275C15.3994 16.0125 15.9544 14.7975 16.0894 13.4025C16.1869 12.405 16.7194 11.55 17.5894 10.9875C18.0844 10.665 18.6694 10.5 19.2844 10.5ZM19.2844 9C18.4069 9 17.5294 9.24 16.7719 9.7275C15.4519 10.575 14.7244 11.895 14.5969 13.26C14.4994 14.25 14.1244 15.1725 13.4344 15.8625L13.4119 15.885C12.5419 16.755 12.5419 18.0825 13.3594 18.8925L14.1019 19.635C14.5144 20.0475 15.0469 20.25 15.5794 20.25C16.1119 20.25 16.6519 20.0475 17.0644 19.635C17.7919 18.9075 18.7519 18.51 19.7944 18.3975C20.8219 18.285 21.8269 17.835 22.6219 17.0475C24.6994 14.97 24.4294 11.445 21.8194 9.75C21.0619 9.2475 20.1769 9 19.2844 9ZM12.1969 22.395C12.3994 22.815 12.3319 23.325 11.9794 23.67C11.5444 24.1125 10.8319 24.1125 10.3894 23.67C10.1869 23.4675 10.0819 23.205 10.0669 22.935C9.79688 22.92 9.53438 22.815 9.33187 22.6125C8.88938 22.17 8.88938 21.4575 9.33187 21.0225C9.67687 20.6775 10.1944 20.6025 10.6069 20.805L12.4669 18.9825C12.5719 19.125 12.6919 19.29 12.8269 19.425L13.5694 20.1675C13.7269 20.3175 13.8769 20.445 14.0719 20.5575L12.1969 22.395Z" fill="currentColor"/>
    <path d="M0.652454 5.63053C1.71745 3.80803 4.02745 3.14803 5.90245 4.13053V0.380531H7.40245V4.13053C9.27745 3.14803 11.5875 3.80803 12.6525 5.63053C14.1525 7.88053 10.4025 14.6305 8.90245 14.6305C7.40245 14.6305 7.40245 13.8805 6.65245 13.8805C5.90245 13.8805 5.90245 14.6305 4.40245 14.6305C2.90245 14.6305 -0.847546 7.88053 0.652454 5.63053ZM1.96495 8.16553C2.42995 10.018 3.34495 11.7205 4.62745 13.1305C4.77745 13.1305 4.94995 13.0555 5.07745 12.943C5.99995 12.2005 7.30495 12.2005 8.22745 12.943C8.35495 13.0555 8.52745 13.1305 8.67745 13.1305C9.95995 11.728 10.8825 10.018 11.34 8.17303C11.5275 7.62553 11.565 7.03303 11.4075 6.47053C10.9725 5.69803 10.1625 5.20303 9.27745 5.18053C8.85745 5.18803 8.44495 5.28553 8.06995 5.47303L7.40245 5.81053H5.90245L5.23495 5.47303C4.85995 5.28553 4.44745 5.18803 4.02745 5.18053C3.14245 5.20303 2.33245 5.69053 1.89745 6.46303C1.73995 7.02553 1.77745 7.61803 1.96495 8.16553ZM7.40245 1.88053C11.6175 4.18303 12.57 0.965531 12.57 0.965531C12.57 0.965531 10.575 -1.72697 7.40245 1.88053Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_6206_870">
    <rect width="24" height="24" fill="none"/>
    </clipPath>
    </defs>
    </svg>
    