import { Component } from '@angular/core';

@Component({
  selector: 'capacity-icon',
  standalone: true,
  imports: [],
  templateUrl: './capacity.component.html',
  styleUrl: './capacity.component.scss'
})
export class CapacityIcon {}
