import { Component, OnDestroy, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '../../../core/global-constants';
import { ApiService } from '../../../core/services/api.service';
import { LocalService } from '../../../core/services/local.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SectionSwitchComponent } from '../../../shared/components/section-switch/section-switch.component';
import { Category, CategoryWithSpirits } from '../../../core/models/category.model';
import { ParentCategory } from '../../../core/models/parentCategory.model';
import { catchError, forkJoin, mergeMap, of, Subject, takeUntil } from 'rxjs';
import { SpiritImagePipe } from '../../../core/pipes/spiritImage';
import { CatalogTranslationPipe } from '../../../core/pipes/catalogTranslation';

const materialModules = [
  MatIconModule,
  MatButtonModule
]

const components = [
  SectionSwitchComponent
]

const pipes = [
  CatalogTranslationPipe
]

@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [TranslateModule, SpiritImagePipe, ...materialModules, ...components, ...pipes],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss'
})
export class CategoriesComponent implements OnInit, OnDestroy {

  SECTIONS = GlobalConstants.SECTIONS;

  private api = inject(ApiService)
  private local = inject(LocalService);
  private translate = inject(TranslateService);

  private destroy$ = new Subject<void>();

  section = signal(this.SECTIONS.MENU);

  menu: any;

  allCategories: WritableSignal<CategoryWithSpirits[]> = signal([]);
  parentCategories: WritableSignal<ParentCategory[]> = signal([]);
  currentCategories: WritableSignal<CategoryWithSpirits[]> = signal([]);
  childCategories: WritableSignal<CategoryWithSpirits[]> = signal([]);

  showChildCategoriesFlag: WritableSignal<boolean> = signal(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.manageLocalData();
    this.getMenu();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  manageLocalData() {
    this.section.set(this.local.getData('section') ?? this.SECTIONS.MENU);
    this.local.removeData('selectedFilters');
    Object.values(this.SECTIONS).forEach(section => {
      this.local.removeData('spirits' + section);
    });
    this.api.clearSelectedFilters();
  }

  getMenu() {
    this.api.getMenu().subscribe(
      data => {
        this.menu = data;
        this.setLang(data.locales[0].locale.code);
        this.getCategories();
      }
    );
  }

  getCategories() {
    this.api.getCompanyCategories().subscribe(
      data => {
        const sortedCategories = this.sortCategories(data);
        const values: CategoryWithSpirits[] = Object.values(sortedCategories);
        this.allCategories.set(values);
        this.manageParentAndCurrentCategories();
        this.setLocalStorageImages();
        this.getCategoriesImages();
      }
    )
  }

  manageParentAndCurrentCategories() {
    const categoriesWithoutParent = this.allCategories().filter((category: CategoryWithSpirits) => !category.parentCategory);
    const categoriesWithParent = this.allCategories().filter((category: CategoryWithSpirits) => category.parentCategory);

    const parents: ParentCategory[] = [];
    categoriesWithParent.forEach((category: CategoryWithSpirits) => {
      const parent = parents.find(x => x.uuid == category.parentCategory?.uuid);
      if (!parent) {
        const { name, uuid, translations } = category.parentCategory!;
        const spirits = category.spirits ? [...category.spirits] : [];
        const totalSpirits = category.totalSpirits ?? 0;
        parents.push({name, uuid, spirits, totalSpirits, translations});
      }
    });

    this.parentCategories.set(parents);
    this.currentCategories.set(categoriesWithoutParent);
  }

  setLocalStorageImages() {
    const sCategories = this.local.getDataWithExpiration('categories');
    if (sCategories) {
      const categories = JSON.parse(sCategories);
      this.allCategories().map(category => {
        const localCategory = categories.find((x: CategoryWithSpirits) => x.uuid == category.uuid);
        if (localCategory && localCategory.totalSpirits) {
          const data: any = {
            results: localCategory.spirits,
            totalItems: localCategory.totalSpirits
          };

          category.spirits = data.results;
          category.totalSpirits = data.totalItems;
          
          if (category.parentCategory) {
            this.addImagesToParent(category, data);
          }
  
          this.addImagesToCurrent(category, data);
        }
      });
    }
  }

  getCategoriesImages() {
    const imageRequests = this.allCategories().map(category => 
      this.api.getCategorySpirits(category, 3).pipe(
        catchError(err => of({ results: [], totalItems: 0 })),
        mergeMap(data => {
          category.spirits = data.results;
          category.totalSpirits = data.totalItems;
          
          if (category.parentCategory) {
            this.addImagesToParent(category, data);
          }

          this.addImagesToCurrent(category, data);
          this.local.setDataWithExpiration('categories', JSON.stringify(this.currentCategories()), 0, 5);

          return of(data);
        })
      )
    );

    forkJoin(imageRequests).pipe(takeUntil(this.destroy$)).subscribe();
  }

  addImagesToParent(category: CategoryWithSpirits, data: any) {
    const parent = this.parentCategories().find(x => x.uuid == category.parentCategory?.uuid);
    parent!.totalSpirits! += data.totalItems;

    const currentSpiritsLength = parent!.spirits?.length ?? 0;
    if (currentSpiritsLength < 3) {
      for (let i = 0; i < 3 - currentSpiritsLength; i++) {
        parent!.spirits!.push(data.results[i]);
      }
    } 

    if (currentSpiritsLength > 0 && currentSpiritsLength == 3) {
      const randNumber = this.getRandomInt(currentSpiritsLength);
      parent!.spirits![randNumber] = data.results[0];
    }
  }

  addImagesToCurrent(category: CategoryWithSpirits, data: any) {
    const current = this.currentCategories().find(x => x.uuid == category.uuid);
    if (current) {
      current!.spirits = data.results;
      current!.totalSpirits = data.totalItems;
    }
  }

  showChildCategories(parent: ParentCategory) {
    const childCategories = this.allCategories().filter((category: Category) => category.parentCategory?.uuid == parent.uuid);
    this.childCategories.set(childCategories);
    this.showChildCategoriesFlag.set(true);
  }

  returnFromChild() {
    this.childCategories.set([]);
    this.showChildCategoriesFlag.set(false);
  }

  goHome() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  navigate(category: Category) {
    this.api.setSelectedFilter('category', [category]);
    this.router.navigate(['../menu'], {relativeTo: this.route});
  }

  changeSection(section: string) {
    this.section.update(() => section);
  }

  private setLang(lang: string) {
    const currentLang = this.local.getData('language') ?? lang;
    this.translate.use(currentLang);
  }

  private getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  private sortCategories(data: CategoryWithSpirits[]) {
    const orderMap: { [key: string]: number } = {};
    this.menu.menuCategories.forEach((menuCategory: any) => {
      orderMap[menuCategory.category.uuid] = menuCategory.order;
    });

    const sortedCategories = data.sort((a, b) => {
      return (orderMap[a.uuid] || 0) - (orderMap[b.uuid] || 0);
    });

    return sortedCategories;
  }
}