<div class="header">
    <h2 mat-dialog-title>{{ filters() || view() || cart() || favorites() ? ('modals.reset_local.title' | translate) : ('modals.reset_local.title_default' | translate) }}</h2>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
@if (filters() || view() || cart() || favorites()) {
    <mat-dialog-content>
        <span class="message">{{ 'modals.reset_local.text' | translate }}</span>
        <ul>
            @if (filters()) {
                <li class="text-white">{{ 'filters' | translate }}</li>
            }
            @if (view()) {
                <li class="text-white">{{ 'view' | translate }}</li>
            }
            @if (cart()) {
                <li class="text-white">{{ 'cart' | translate }}</li>
            }
            @if (favorites()) {
                <li class="text-white">{{ 'favorites' | translate }}</li>
            }
        </ul>
    </mat-dialog-content>
}
<mat-dialog-actions align="end">
    <button class="btn-white" mat-button (click)="close()">{{ filters() || view() || cart() || favorites() ? ('modals.reset_local.close' | translate) : ('modals.reset_local.got_it' | translate) }}</button>
    @if (filters() || view() || cart() || favorites()) {
        <button mat-flat-button color="primary" (click)="reset()">{{ 'modals.reset_local.reset' | translate }}</button>
    }
</mat-dialog-actions>