import { Component, Input, inject } from '@angular/core';
import { GroupedItemComponent } from '../grouped-item/grouped-item.component';
import { KeyValuePipe } from '@angular/common';
import { Spirit } from '../../../../core/models/spirit.model';
import { GlobalConstants } from '../../../../core/global-constants';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LocalService } from '../../../../core/services/local.service';
import { PriceVariantTranslationPipe } from '../../../../core/pipes/priceVariantTranslation';

const pipes = [
  PriceVariantTranslationPipe
]

@Component({
  selector: 'app-grouped',
  standalone: true,
  imports: [...pipes, GroupedItemComponent, KeyValuePipe, TranslateModule],
  templateUrl: './grouped.component.html',
  styleUrl: './grouped.component.scss'
})
export class GroupedComponent {

  @Input() uuid: string = '';
  @Input() spirits: Spirit[] | null = [];
  @Input() menu: any;
  @Input() section: string = '';

  private local = inject(LocalService);

  SECTIONS = GlobalConstants.SECTIONS;

  constructor(
    private router: Router
  ) {}

  navigate(spirit: Spirit) {
    this.local.setDataWithExpiration('detailSpirit', JSON.stringify(spirit), 0, 1);
    this.router.navigate([`/${this.uuid}/menu/${spirit.uuid}`]);
  }
}
