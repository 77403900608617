import { Component, EventEmitter, Output, WritableSignal, inject, signal } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { FilterComponent } from '../../filters/filter/filter.component';
import { PriceVariantComponent } from '../../filters/price-variant/price-variant.component';
import { CategoryFilter } from '../../../../core/models/categoryFilter.model';
import { SelectedFilters } from '../../../../core/models/selectedFilters.model';
import { Filters } from '../../../../core/models/filters.model';
import { DataIconComponent } from '../../../../shared/components/data-icon/data-icon.component';
import { CatalogTranslationPipe } from '../../../../core/pipes/catalogTranslation';

const materialModules = [
  MatIconModule,
  MatBottomSheetModule
]

const components = [
  DataIconComponent
]

const pipes = [
  CatalogTranslationPipe
]

@Component({
  selector: 'app-footer-desktop-filters',
  standalone: true,
  imports: [TranslateModule, ...materialModules, ...components, ...pipes],
  templateUrl: './desktop-filters.component.html',
  styleUrl: './desktop-filters.component.scss'
})
export class DesktopFiltersComponent {

  @Output() update = new EventEmitter();

  private api = inject(ApiService);

  categoryFilters: WritableSignal<CategoryFilter[]|null> = signal(null);
  allFilters: WritableSignal<Filters|null> = signal(null);

  constructor(
    private _bottomSheet?: MatBottomSheet
  ) {}

  ngOnInit() {
    this.getCategoryFilters();
  }

  getCategoryFilters() {
    this.api.getCategoryFilters().subscribe(
      data => {
        this.categoryFilters.set(data);
        if (data.length > 0) {
          this.getAllFilterData(data[0].uuid);
          this.getSelectedFiltersCount();
        }
      }
    );
  }

  getAllFilterData(filterUuid: string) {
    this.api.getAllFilterData(filterUuid).subscribe(
      data => {
        this.allFilters.set(data);
      }
    );
  }

  openFilter(categoryFilter: CategoryFilter) {
    const bottomSheetRef = this._bottomSheet!.open(FilterComponent, {
      data: { categoryFilter, allFilters: this.allFilters() },
      panelClass: 'mat-bottom-sheet-container-medium'
    });

    bottomSheetRef.afterDismissed().subscribe((filtersApplied) => {
      if (filtersApplied) {
        this.update.emit();
        this.getSelectedFiltersCount();
      }
    });
  }

  openPriceVariantFilter() {
    this._bottomSheet!.open(PriceVariantComponent, {
      panelClass: 'mat-bottom-sheet-container-medium'
    });
  }

  getSelectedFiltersCount() {
    const selectedFilters = this.api.getSelectedFilters();
    this.categoryFilters()?.forEach(categoryFilter => {
      const key = categoryFilter.filter.name;
      categoryFilter.selectedCount = selectedFilters[key as keyof SelectedFilters].length;
    });
  }
}