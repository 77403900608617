import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  standalone: true,
  name: 'userImage'
})
export class UserImagePipe implements PipeTransform {

    private url: string = environment.api + '/images/user/';

    transform(image: string | undefined): string {
      if (!image) {
        return '';
      }
      return this.url + image;
    }
}