import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { LocalService } from '../../../../core/services/local.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { GlobalConstants } from '../../../../core/global-constants';

const materialModules = [
  MatFormFieldModule,
  MatButtonModule,
  MatInputModule,
  MatIconModule,
  FormsModule
]

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [TranslateModule, ...materialModules],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {

  SECTIONS = GlobalConstants.SECTIONS;

  private local = inject(LocalService)

  @Input() section: string = '';
  @Input() search: string = '';

  @Output() update = new EventEmitter<string>();

  searchTimer: any;

  constructor() {}

  applySearch(event: Event) {
    const search = (event.target as HTMLInputElement).value;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(() => {
      this.local.saveData('search', search);
      this.update.emit(search);
    }, 500);
  }

  clearSearch() {
    this.local.saveData('search', '');
    this.update.emit('');
  }

}
