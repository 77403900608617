<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6206_872)">
    <path d="M2 22V20C2.76667 20 3.525 19.95 4.275 19.85C5.025 19.75 5.75833 19.5667 6.475 19.3C5.70833 18.9167 5.10417 18.3625 4.6625 17.6375C4.22083 16.9125 4 16.125 4 15.275V13H8V9.99999H11.375L8.1 3.44999L9.9 2.54999L13.175 9.09999C13.5083 9.76665 13.4833 10.4167 13.1 11.05C12.7167 11.6833 12.15 12 11.4 12H10V13C10 13.55 9.80417 14.0208 9.4125 14.4125C9.02083 14.8042 8.55 15 8 15H6V15.275C6 15.8583 6.17917 16.3708 6.5375 16.8125C6.89583 17.2542 7.35 17.55 7.9 17.7L8.2 17.775C8.86667 17.9417 9.24167 18.3583 9.325 19.025C9.40833 19.6917 9.15 20.1917 8.55 20.525C7.55 21.075 6.49583 21.4583 5.3875 21.675C4.27917 21.8917 3.15 22 2 22Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2732 19.9092L27.9096 20.5456L21.5457 26.9095L20.9093 26.2731L22.1821 22.4548L20.8456 21.1183C19.785 21.882 17.9659 22.8525 16.6613 22.7571C15.3567 22.6616 14.2271 22.1366 13.2725 21.182L9.45415 16.0908L10.7269 14.818L13.9089 19.2728L15.2772 20.4501C15.9772 20.8532 16.7303 21.0123 17.5364 20.9274C18.3425 20.8426 19.6789 19.8668 20.2729 19.2728C20.8668 18.6788 21.8427 17.3424 21.9275 16.5363C22.0124 15.7302 21.8533 14.9771 21.4502 14.2771L20.2729 12.9088L15.8181 9.72685L17.0909 8.45405L22.1821 12.2724C23.1367 13.227 23.6617 14.3566 23.7571 15.6612C23.8526 16.9659 22.8821 18.7849 22.1184 19.8455L23.4549 21.182L27.2732 19.9092Z" fill="currentColor"/>
    <path d="M22.0426 12.933C18.0915 16.0405 15.3769 15.703 10.7413 15.9612L11.2071 17.6999C16.6527 17.2199 19.8345 17.0381 22.5085 14.6717L22.0426 12.933Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_6206_872">
    <rect width="24" height="24" fill="none"/>
    </clipPath>
    </defs>
    </svg>
    