import { Component } from '@angular/core';

@Component({
  selector: 'style-icon',
  standalone: true,
  imports: [],
  templateUrl: './style.component.html',
  styleUrl: './style.component.scss'
})
export class StyleIcon {

}
