import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { VasoIcon } from '../../../../shared/icons/vaso/vaso.component';

const icons = [
  VasoIcon
]

const materialModules = [
  MatIconModule,
  MatButtonModule
]

@Component({
  selector: 'app-fast-copa',
  standalone: true,
  imports: [...materialModules, ...icons],
  templateUrl: './fast-copa.component.html',
  styleUrl: './fast-copa.component.scss'
})
export class FastCopaComponent {

}
