<header>
    <span class="text-white">Precio</span>
    <button mat-icon-button class="text-light"><mat-icon>close</mat-icon></button>
</header>

<main class="price-variant">
    <ngx-slider [(value)]="minPrice" [(highValue)]="maxPrice" [options]="pricesOptions"></ngx-slider>
    <div class="variants">
        <mat-checkbox>Chupito</mat-checkbox>
        <mat-checkbox>Medio vaso</mat-checkbox>
        <mat-checkbox>Vaso</mat-checkbox>
        <mat-checkbox>Botella</mat-checkbox>
    </div>
</main>