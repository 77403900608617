import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Spirit } from '../../../../core/models/spirit.model';
import { TranslateModule } from '@ngx-translate/core';
import { SpiritImagePipe } from '../../../../core/pipes/spiritImage';

const pipes = [
  SpiritImagePipe
]

@Component({
  selector: 'app-carousel-item',
  standalone: true,
  imports: [TranslateModule, ...pipes],
  templateUrl: './carousel-item.component.html',
  styleUrl: './carousel-item.component.scss'
})
export class CarouselItemComponent implements OnInit {
  
  @Input() spirit!: Spirit;
  @Input() menu: any;
  @Output() goToDetail = new EventEmitter<Spirit>();

  soldout: boolean = false;
  new: boolean = false;

  ngOnInit(): void {
    this.soldout = this.spirit.soldout && this.menu.modules?.includes('soldout');
    this.new = this.spirit.soldout && this.menu.modules?.includes('soldout');
  }

  navigate(spirit: Spirit) {
    this.goToDetail.emit(spirit);
  }

  favorite(spirit: Spirit) {
    
  }

}
