import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../core/services/api.service';
import { LocalService } from '../../core/services/local.service';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ResetStorageComponent } from './reset-storage/reset-storage.component';
import { CoverImagePipe } from '../../core/pipes/coverImage';
import { UserImagePipe } from '../../core/pipes/userImage';
import { Platform } from '@ionic/angular';
import { AppLauncher } from '@capacitor/app-launcher';

const materialModules = [
  MatIconModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDialogModule,
  MatButtonModule
]

const pipes = [
  CoverImagePipe,
  UserImagePipe
]

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [TranslateModule, ...materialModules, ...pipes],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  private api = inject(ApiService)
  private local = inject(LocalService);
  private translate = inject(TranslateService);

  menu: any;
  selectedLanguage: string = '';

  coverPage: boolean = false;
  cover: any;

  languages: {code: string, language: string}[] = [
    {code: 'es', language: 'Español'},
    {code: 'en', language: 'English'},
    {code: 'eu', language: 'Euskera'},
    {code: 'fr', language: 'Français'},
    {code: 'it', language: 'Italiano'},
    {code: 'de', language: 'Deutsch'},
    {code: 'ca', language: 'Català'},
    {code: 'ar', language: 'العربية'},
    {code: 'gl', language: 'Galego'},
    {code: 'ja', language: '日本語'},
    {code: 'no', language: 'Norsk'},
    {code: 'pt', language: 'Português'},
    {code: 'ru', language: 'Pусский'},
    {code: 'sv', language: 'Svenska'},
    {code: 'zh', language: '中文'},
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    this.getMenu();
  }

  getMenu() {
    this.api.getMenu().subscribe(
      data => {
        this.menu = data;

        let mainLocale = data.locales.find((x: any) => x.main);
        if (!mainLocale)
          mainLocale = data.locales[0];

        this.selectedLanguage = mainLocale.locale.code;
        this.translate.use(mainLocale.locale.code);
        this.local.saveData('language', mainLocale.locale.code);
      }
    );
  }

  getCoverPage() {
    this.api.getCoverPage().subscribe(
      data => {
        if (!data.photo) {
          this.coverPage = false;
        }
        if (data.sections && data.types) {
          data.types.forEach((type: any) => {
            const section = data.sections.find((x: any) => x.name == type.type);
            if (section) {
              type.translation = section?.translation;
            }
          });
        }
        if (data.sections) {
          delete data['sections'];
        }
        data.types.sort((a: any, b: any) => b['1'] - a['1']);
        this.cover = data;
      }
    );
  }

  switchLanguage() {
    this.translate.use(this.selectedLanguage);
    this.local.saveData('language', this.selectedLanguage);
  }

  getLanguageName(language: string): string {
    return this.languages.find(x => x.code == language)?.language ?? this.menu.locales[0].code;
  }

  navigate() {
    this.router.navigate(['categories'], {relativeTo: this.route});
  }

  resetLocalStorage() {
    this.dialog.open(ResetStorageComponent, {
      maxWidth: '95vw',
      minWidth: '350px',
      width: '500px',
      panelClass: 'modal-no-padding'
    });
  }

  async goToWinerim() {
    if (this.platform.is('hybrid')) {
      const { completed } = await AppLauncher.openUrl({ url: 'winerim://' });
      if (!completed) {
        window.open(this.menu.user.winerimUrl, '_blank');
      }
    } else {
      window.open(this.menu.user.winerimUrl, '_blank');
    }
  }
}
