<header>
    <span class="text-white">{{ data.categoryFilter?.name ?? ('filters.' + data.categoryFilter.filter.name | translate) }}</span>
    <button mat-flat-button color="accent" class="text-light" [disabled]="!changes()" (click)="applyFilters()">{{ 'menu.apply' | translate }}</button>
</header>

<main>
    <mat-chip-set>
        @defer(when filters()) {

            @for(filter of filters(); track filter.uuid) {
                <mat-chip-row color="accent" [class.selected]="filter.selected" [class.disabled]="filter.count == 0" (click)="toggleFilter(filter)">
                    <span>{{ filter | catalogTranslation }}</span>
                    <span class="count">({{ filter.count }})</span>
                </mat-chip-row>
            }

            @if (filters()?.length == 0) {
                <span class="text-white no-filters">{{ 'filters.empty' | translate }}</span>
            }

        }@placeholder {

            <mat-chip-row color="accent" class="filter placeholder-wave placeholder-1 placeholder"></mat-chip-row>
            <mat-chip-row color="accent" class="filter placeholder-wave placeholder-2 placeholder"></mat-chip-row>
            <mat-chip-row color="accent" class="filter placeholder-wave placeholder-3 placeholder"></mat-chip-row>
            <mat-chip-row color="accent" class="filter placeholder-wave placeholder-4 placeholder"></mat-chip-row>

        }
    </mat-chip-set>
</main>