import { Component, Input, ViewChild, WritableSignal, inject, signal } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { LocalService } from '../../../core/services/local.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Spirit } from '../../../core/models/spirit.model';
import { ActivatedRoute, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { SectionSwitchComponent } from '../../../shared/components/section-switch/section-switch.component';
import { MenuTitleComponent } from '../header/menu-title/menu-title.component';
import { ViewSwitchComponent } from '../header/view-switch/view-switch.component';
import { SearchComponent } from '../header/search/search.component';
import { MatButtonModule } from '@angular/material/button';
import { GridComponent } from '../views/grid/grid.component';
import { DesktopFiltersComponent } from '../footer/desktop-filters/desktop-filters.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselComponent } from '../views/carousel/carousel.component';
import { GridPlaceholderComponent } from '../views/grid-placeholder/grid-placeholder.component';
import { CarouselPlaceholderComponent } from '../views/carousel-placeholder/carousel-placeholder.component';
import { GlobalConstants } from '../../../core/global-constants';
import { GroupedComponent } from '../views/grouped/grouped.component';
import { GroupedPlaceholderComponent } from '../views/grouped-placeholder/grouped-placeholder.component';
import { FastCopaComponent } from '../header/fast-copa/fast-copa.component';
import { MobileFiltersButtonComponent } from '../footer/mobile-filters-button/mobile-filters-button.component';
import { SelectedFiltersComponent } from '../header/selected-filters/selected-filters.component';
import { SpiritsEmptyComponent } from '../views/spirits-empty/spirits-empty.component';

const modules = [
  InfiniteScrollModule,
  TranslateModule,
  RouterOutlet
]

const components = [
  MenuTitleComponent,
  SectionSwitchComponent,
  ViewSwitchComponent,
  SearchComponent,
  GridComponent,
  GridPlaceholderComponent,
  CarouselComponent,
  CarouselPlaceholderComponent,
  GroupedComponent,
  GroupedPlaceholderComponent,
  DesktopFiltersComponent,
  FastCopaComponent,
  MobileFiltersButtonComponent,
  SelectedFiltersComponent,
  SpiritsEmptyComponent
]

const materialModules = [
  MatIconModule,
  MatButtonModule
];

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [...modules, ...components, ...materialModules],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {

  @ViewChild('appSelectedFilters') appSelectedFilters: SelectedFiltersComponent = new SelectedFiltersComponent;
  @ViewChild('appSelectedFiltersMobile') appSelectedFiltersMobile: SelectedFiltersComponent = new SelectedFiltersComponent;
  @ViewChild('appDesktopFilters') appDesktopFilters: DesktopFiltersComponent = new DesktopFiltersComponent;
  @ViewChild('appCarousel') appCarousel!: CarouselComponent;

  VIEWS = GlobalConstants.VIEWS;
  SECTIONS = GlobalConstants.SECTIONS;

  @Input() uuid: string = '';
  slugname: string = '';

  private local = inject(LocalService);
  private api = inject(ApiService);
  private translate = inject(TranslateService);

  view = signal('');
  section = signal(this.SECTIONS.MENU);
  search = signal('');

  spirits: WritableSignal<Spirit[] | null> = signal(null);
  groupedSpirits: WritableSignal<any> = signal(null);
  totalSpirits: number = 0;

  fastGlassFilter = true;
  page = 0;
  limitReached = false;

  menu: any;
  countries: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.slugname = this.route.snapshot.url[0].path;
    
    this.getMenu();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.checkFavChanges();
      }
    });
  }

  getMenu() {
    this.api.getMenu().subscribe(
      data => {
        this.menu = data;
        this.getLocalData();
      }
    );
  }

  getLocalData() {
    this.section.set(this.local.getData('section') ?? this.SECTIONS.MENU);
    this.view.set(this.local.getDataWithExpiration('view') ?? this.menu.defaultView ?? this.VIEWS.CAROUSEL);
    this.search.set(this.local.getData('search') ?? '');
    this.getSpirits();
  }

  getSpirits() {
    if (this.section() == this.SECTIONS.FAVORITES) {
      this.getFavorites();
      return;
    }

    if (this.limitReached) {
      return;
    }
    this.page ++;

    if (this.page === 1) {
      const spirits = this.local.getDataWithExpiration('spirits' + this.section());
      if (spirits) {
        this.spirits.update(() => JSON.parse(spirits));
      } else {
        this.spirits.update(() => null);
      }
    }

    this.getCompanySpirits();
  }

  getCompanySpirits() {
    this.api.getCompanySpirits(this.page, this.section(), this.search()).subscribe(
      data => {
        if (this.page === 1) {
          this.totalSpirits = 0;
          this.spirits.set(data.results);
          this.local.setDataWithExpiration('spirits' + this.section(), JSON.stringify(data.results), 0, 1);
        } else {
          this.spirits.update(spirits => {
            return [...spirits!, ...data.results]
          });
        }

        this.totalSpirits = data.totalItems ?? 0;
        if (!data.pagination[0].more) {
          this.limitReached = true;
        }

        if (this.view() == this.VIEWS.GRID) {
          this.fillWindow();
        }
      }
    );
  }

  // MODIFY VIEW / FILTERS

  changeSection(section: string) {
    this.section.update(() => section);

    if (section == this.SECTIONS.FAVORITES) {
      this.getFavorites();
    } else {
      this.restartSpirits();
    }
  }

  changeView(view: string) {
    this.view.update(() => view);

    if (!this.spirits()) {
      this.getSpirits();
    }

    // if ((view == this.VIEWS.GRID || view == this.VIEWS.CAROUSEL) && !this.spirits()) {
    //   this.getSpirits();
    // }

    // if (view == this.VIEWS.TRADITIONAL && !this.groupedSpirits()) {
    //   this.getSpirits();
    // }

    if (view == this.VIEWS.GRID) {
      this.fillWindow();
    }
  }

  changeSearch(search: string) {
    this.search.update(() => search);
    this.restartSpirits();
  }

  applyFilters() {
    this.restartSpirits();
    this.updateSelectedFilters();
  }

  restartSpirits() {
    this.limitReached = false;
    this.page = 0;
    this.spirits.update(() => null);
    this.getSpirits();
  }

  goHome() {
    this.router.navigate(['../categories'], {relativeTo: this.route});
  }

  getFavorites() {
    const localFavorites = this.local.getData('favorites');
    const favorites = localFavorites ? JSON.parse(localFavorites) : {};
    const currentCategory = this.api.getSelectedFilters().category[0].uuid;
    const categoryFavorites = favorites[currentCategory as keyof Object] ?? [];
    this.spirits.set(categoryFavorites);
    this.totalSpirits = this.spirits()?.length ?? 0;

    if (this.view() == this.VIEWS.CAROUSEL && categoryFavorites && categoryFavorites.length > 0) {
      setTimeout(() => {
        this.appCarousel.initialize();
      }, 100);
    }
  }

  private setLang(lang: string) {
    const currentLang = this.local.getData('language') ?? lang;
    this.translate.use(currentLang);
  }

  private fillWindow() {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const gridHeight = windowHeight - 220;
    const gridItemSize = 24000; // height * width ~ 80 * 370

    if (this.spirits()!.length < (gridHeight * windowWidth) / gridItemSize) {
      this.getSpirits();
    }
  }

  private updateSelectedFilters() {
    this.appSelectedFilters.updateSelectedFilters();
    this.appSelectedFiltersMobile.updateSelectedFilters();
    this.appDesktopFilters.getSelectedFiltersCount();
  }

  private checkFavChanges() {
    const favUpdatedSpirit = this.local.getData('favUpdatedSpirit');
    if (favUpdatedSpirit) {
      const updatedSpirit = JSON.parse(favUpdatedSpirit);
      this.local.removeData('favUpdatedSpirit');
      const spirit = this.spirits()?.find(x => x.uuid == updatedSpirit.uuid);
      if (spirit) {
        spirit.fav = updatedSpirit.fav;
      }
    }
  }
}