import { Component } from '@angular/core';

@Component({
  selector: 'elaboration-icon',
  standalone: true,
  imports: [],
  templateUrl: './elaboration.component.html',
  styleUrl: './elaboration.component.scss'
})
export class ElaborationIcon {

}
