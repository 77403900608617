import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationHelperService { 
  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService

  ) {
    this.translateService.onLangChange.subscribe(event => {
      this.languageService.setCurrentLang(event.lang);
    });
  }
}
