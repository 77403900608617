import { Component, EventEmitter, Input, Output, WritableSignal, inject, signal } from '@angular/core';
import { IntermediateFilter } from '../../../core/models/intermediateFilter.model';
import { Filter } from '../../../core/models/filter.model';
import { ApiService } from '../../../core/services/api.service';
import { SelectedFilters } from '../../../core/models/selectedFilters.model';
import { Filters } from '../../../core/models/filters.model';
import { IntermediateFilterListComponent } from '../intermediate-filter-list/intermediate-filter-list.component';

const components = [
  IntermediateFilterListComponent
]

@Component({
  selector: 'app-intermediate-filter',
  standalone: true,
  imports: [...components],
  templateUrl: './intermediate-filter.component.html',
  styleUrl: './intermediate-filter.component.scss'
})
export class IntermediateFilterComponent {

  @Input() intermediateFilter: IntermediateFilter = new IntermediateFilter;
  @Input() selectedIntermediateFilterId: number = -1;
  @Input() allFilters: Filters|null = null;

  @Output() openFilterEvent = new EventEmitter<number>();
  @Output() closeFilterEvent = new EventEmitter();

  private api = inject(ApiService);

  filters: WritableSignal<Filter[]|null> = signal(null);

  openFilter(): void {
    if (this.selectedIntermediateFilterId != this.intermediateFilter.id) {
      this.getFilterData();
      this.openFilterEvent.emit(this.intermediateFilter.id);
    }
  }

  getFilterData() {
    this.api.getFilterData(this.intermediateFilter.uuid).subscribe(
      data => {
        this.manageFilters(this.allFilters![this.intermediateFilter.type], data[this.intermediateFilter.type]);
        this.filters.set(data[this.intermediateFilter.type]);
        this.getSelectedFilters();
      }
    );
  }

  manageFilters(allFilters: Filter[], filters: Filter[]) {
    allFilters.forEach(filter => {
      if (!filters.find(x => x.uuid == filter.uuid)) {
        filter.count = 0;
        filters.push(filter);
      }
    });
  }

  getSelectedFilters() {
    const selectedFilters = this.api.getSelectedFilters();
    this.filters()?.forEach(filter => {
      const key: keyof SelectedFilters = this.intermediateFilter.type;
      if (selectedFilters[key].find((x: Filter) => x.uuid == filter.uuid)) {
        filter.selected = true;
      }
    });
  }

  closeIntermediateFilter() {
    this.closeFilterEvent.emit();
  }
}
