import { Component, Inject, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ApiService } from '../../../../core/services/api.service';
import { Filter } from '../../../../core/models/filter.model';
import { CategoryFilter } from '../../../../core/models/categoryFilter.model';
import { TranslateModule } from '@ngx-translate/core';
import { SelectedFilters } from '../../../../core/models/selectedFilters.model';
import { CatalogTranslationPipe } from '../../../../core/pipes/catalogTranslation';

const materialModules = [
  MatIconModule,
  MatButtonModule,
  MatChipsModule,
  MatBottomSheetModule
];

const pipes = [
  CatalogTranslationPipe
]

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [TranslateModule, ...materialModules, ...pipes],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent implements OnInit {

  private api = inject(ApiService);

  filters: WritableSignal<Filter[]|null> = signal(null);
  changes: WritableSignal<boolean> = signal(false);

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomsheet: MatBottomSheetRef<FilterComponent>
  ) { }

  ngOnInit(): void {
    this.getFilterData(this.data.categoryFilter);
  }

  getFilterData(categoryFilter: CategoryFilter) {
    this.api.getFilterData(categoryFilter.uuid).subscribe(
      data => {
        this.manageFilters(this.data.allFilters[categoryFilter.filter.name], data[categoryFilter.filter.name]);
        this.filters.set(data[categoryFilter.filter.name]);
        this.getSelectedFilters();
      }
    );
  }

  manageFilters(allFilters: Filter[], filters: Filter[]) {
    allFilters.forEach(filter => {
      if (!filters.find(x => x.uuid == filter.uuid)) {
        filter.count = 0;
        filters.push(filter);
      }
    });
  }

  getSelectedFilters() {
    const selectedFilters = this.api.getSelectedFilters();
    this.filters()?.forEach(filter => {
      const key: keyof SelectedFilters = this.data.categoryFilter.filter.name;
      if (selectedFilters[key].find((x: Filter) => x.uuid == filter.uuid)) {
        filter.selected = true;
      }
    });
  }

  toggleFilter(filter: Filter) {
    filter.selected = !filter.selected;
    this.changes.set(true);
  }

  applyFilters() {
    const selected = this.filters()?.filter(x => x.selected) ?? [];
    this.api.setSelectedFilter(this.data.categoryFilter.filter.name, selected);
    this.bottomsheet.dismiss(true);
  }
}
