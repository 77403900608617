import { Component } from '@angular/core';

@Component({
  selector: 'price-icon',
  standalone: true,
  imports: [],
  templateUrl: './price.component.html',
  styleUrl: './price.component.scss'
})
export class PriceIcon {}
