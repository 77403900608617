@defer(when showContent) {
    <div id="shortcode">
        <div class="container">
            <img src="assets/images/logo-white.png"/>
            <span>{{ 'shortcode.title' | translate }}</span>
            <mat-form-field appearance="outline">
                <input matInput type="text" [(ngModel)]="slugname" autocapitalize="off">
                @if(error) {
                    <span class="shortcode-error">{{ 'shortcode.error' | translate }}</span>
                }
            </mat-form-field>
            <button mat-flat-button color="accent" (click)="submit()">{{ 'shortcode.action' | translate }}</button>
            <!-- <div class="shortcode">
                <input matInput [(ngModel)]="shortcode" autocomplete="off" placeholder="XXXX-XXXX" maxlength="9" (input)="shortcodeInput($event)" (paste)="shortcodePaste($event)">
                <div class="shortcode-gradient" [class.error]="error"></div>
                
            </div> -->
        </div>
    </div>
}@placeholder {
    <mat-spinner></mat-spinner>
}