import { Component, OnInit, inject } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Platform } from '@ionic/angular/common';
import { Preferences } from '@capacitor/preferences';

const materialModules = [
  MatInputModule,
  FormsModule,
  MatButtonModule,
  MatProgressSpinnerModule
];

@Component({
  selector: 'app-shortcode',
  standalone: true,
  imports: [TranslateModule, ...materialModules],
  templateUrl: './shortcode.component.html',
  styleUrl: './shortcode.component.scss'
})
export class ShortcodeComponent implements OnInit {

  private api = inject(ApiService);

  uuid: string = '';
  slugname: any = '';
  error = false;
  showContent = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    if (this.platform.is('hybrid')) {
      this.getSavedSlugname().then(
        (slugname) => {
          if (slugname.value) {
            this.slugname = slugname.value;
            this.submit();
          } else {
            this.showContent = true;
          }
        }
      );
    } else {
      this.showContent = true;
    }
  }

  submit() {
    this.error = false;
    this.api.setUuid(this.slugname);
    this.api.getMenu().subscribe(
      data => {
        if (this.platform.is('hybrid')) {
          Preferences.set({
            key: 'slugname',
            value: this.slugname
          });
        }
        this.router.navigate(['../' + this.slugname], {relativeTo: this.route});
      },
      error => {
        this.error = true;
      }
    );
  }

  async getSavedSlugname() {
    return Preferences.get({ key: 'slugname' });
  }
}
