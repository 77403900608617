import { Component } from '@angular/core';

@Component({
  selector: 'body-icon',
  standalone: true,
  imports: [],
  templateUrl: './body.component.html',
  styleUrl: './body.component.scss'
})
export class BodyIcon {

}
