import { Component, EventEmitter, Input, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { IntermediateFilter } from '../../../core/models/intermediateFilter.model';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { ApiService } from '../../../core/services/api.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { Filter } from '../../../core/models/filter.model';
import { MatButtonModule } from '@angular/material/button';

const materialModules = [
  MatIconModule,
  MatButtonModule,
  MatBadgeModule,
  MatInputModule,
  MatFormFieldModule,
  FormsModule
]

@Component({
  selector: 'app-intermediate-filter-list',
  standalone: true,
  imports: [TranslateModule, ...materialModules],
  templateUrl: './intermediate-filter-list.component.html',
  styleUrl: './intermediate-filter-list.component.scss'
})
export class IntermediateFilterListComponent implements OnInit {

  @Input() intermediateFilter: IntermediateFilter = new IntermediateFilter;
  @Input() selectedIntermediateFilterId: number = -1;
  @Input() filters: Filter[]|null = [];

  @Output() closeFilterEvent = new EventEmitter();

  private api = inject(ApiService);

  selectedCount: WritableSignal<number> = signal(0);
  search: string = '';
  filterOpened: string|null = null;

  ngOnInit(): void {
    this.getSelectedCount();
  }

  closeFilter(e: Event): void {
    e.stopPropagation();
    this.closeFilterEvent.emit();
  }

  getSelectedCount() {
    const selectedFilters = this.api.getSelectedFilters();
    let count = 0;

    if (this.intermediateFilter.type == 'country') {
      count = selectedFilters[this.intermediateFilter.subfilter].length;
    } else {
      count = selectedFilters[this.intermediateFilter.type].length;
    }

    this.selectedCount.set(count);
  }

  filterFilters(items: any[]) {
    if (this.search) {
      return items.filter(item => 
        (item.translation?.toLowerCase().includes(this.search.toLowerCase()) ?? item.name.toLowerCase().includes(this.search.toLowerCase())) ||
        item[this.intermediateFilter.subfilter]?.find((subfilter: Filter) => subfilter.name.toLowerCase().includes(this.search.toLowerCase()))
      );
    }

    return items;
  }

  filterSubfilters(items: any[]) {
    if (this.search) {
      return items.filter(item => item.translation?.toLowerCase().includes(this.search.toLowerCase()) ?? item.name.toLowerCase().includes(this.search.toLowerCase()));
    }

    return items;
  }

  isSelected(type: string, filter: Filter): boolean {
    const selectedFilters = this.api.getSelectedFilters();
    return selectedFilters[type].filter(x => x.uuid == filter.uuid).length > 0;
  }

  selectFilter(selected: Filter) {
    if (this.filterOpened == selected.uuid) {
      this.filterOpened = null;
      return;
    }

    if (this.intermediateFilter.subfilter && this.filterOpened != selected.uuid) {
      this.filterOpened = selected.uuid;
    } else {
      this.saveSelected(this.intermediateFilter.type, selected);
    }
  }

  selectSubfilter(selected: Filter) {
    this.saveSelected(this.intermediateFilter.subfilter, selected);
  }

  saveSelected(type: string, selectedFilter: Filter) {
    const selectedFilters = this.api.getSelectedFilters();
    const selected = selectedFilters[type] ?? [];

    if (selected.find(x => x.uuid == selectedFilter.uuid)) {
      const item = selected.find(x => x.uuid == selectedFilter.uuid);
      const index = selected.indexOf(item!);
      selected.splice(index, 1);
    } else {
      selected.push(selectedFilter);
    }

    this.api.setSelectedFilter(type, selected);
    this.selectedCount.set(selected.length);
  }

}
