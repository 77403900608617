import { Component } from '@angular/core';

@Component({
  selector: 'bottling-status-icon',
  standalone: true,
  imports: [],
  templateUrl: './bottling-status.component.html',
  styleUrl: './bottling-status.component.scss'
})
export class BottlingStatusIcon {

}
