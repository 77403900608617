import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'spiritTranslation'
})
export class SpiritTranslationPipe implements PipeTransform {

    private translateService = inject(TranslateService)

    transform(spirit: any | undefined, variable: string): string {
        if (!spirit)
            return '';

        const spiritTranslation = spirit.translations?.find((x: any) => x.locale.code == (this.translateService.currentLang ?? 'es'));
        if (spiritTranslation && spiritTranslation[variable])
            return spiritTranslation[variable];

        const catalogTranslation = spirit.catalogSpirit?.translations?.find((x: any) => x.locale.code == (this.translateService.currentLang ?? 'es'));
        if (catalogTranslation && catalogTranslation[variable])
            return catalogTranslation[variable];

        return spirit[variable] ?? '';
    }
}