<main>
    <header>
        @defer (when menu) {

            @if (!showChildCategoriesFlag()) {

                <div class="left">
                    <button mat-icon-button (click)="goHome()"><mat-icon>keyboard_arrow_left</mat-icon></button>
                    <span>{{ 'categories.back' | translate }}</span>
                </div>

            } @else {

                <div class="left">
                    <button mat-icon-button (click)="returnFromChild()"><mat-icon>keyboard_arrow_left</mat-icon></button>
                    <span>{{ 'categories.all' | translate }}</span>
                </div>
                
            }

            <div class="center">
                <img src="/assets/images/logo.png"/>
            </div>
        }
    </header>

    <section>
        <div class="categories">
            @if (!showChildCategoriesFlag()) {

                @for (parent of parentCategories(); track parent.uuid) {
                    <article (click)="showChildCategories(parent)">
                        <h5>{{ parent.name }}</h5>
                        <h6>{{ parent.totalSpirits }} {{ 'categories.bottles' | translate }}</h6>
                        <div class="images">
                            @for (spirit of parent.spirits; track spirit.uuid) {
                                <img [src]="spirit?.catalogSpirit!.image | spiritImage" alt="">
                            }
                        </div>
                    </article>
                }

                @for (category of currentCategories(); track category.uuid) {
                    <article (click)="navigate(category)">
                        <h5>
                            @if (category.uuid == 'e93e770f-65e8-4d2a-8228-0005932b7925') {
                                @defer (when category.totalSpirits) {
                                    {{ category.totalSpirits}}
                                }
                            }
                            {{ category | catalogTranslation }}
                        </h5>
                        @if (category.uuid != 'e93e770f-65e8-4d2a-8228-0005932b7925') {
                            @defer (when category.totalSpirits) {
                                <h6>{{ category.totalSpirits}} {{ 'categories.bottles' | translate }}</h6>
                            }@placeholder {
                                <h6 class="placeholder-wave placeholder"></h6>
                            }
                        } @else {
                            <h6></h6>
                        }
                        <div class="images">
                            @defer (when category.spirits) {
                                @for (spirit of category.spirits; track spirit.uuid) {
                                    <img class="fade-in" [src]="spirit?.catalogSpirit!.image | spiritImage" alt="">
                                }
                            }@placeholder {
                                <div class="placeholder"></div>
                                <div class="placeholder"></div>
                                <div class="placeholder"></div>
                            }
                        </div>
                    </article>
                }

            } @else {

                @for (category of childCategories(); track category.uuid) {
                    <article (click)="navigate(category)">
                        <h5>{{ category | catalogTranslation }}</h5>
                        <h6>{{ category.totalSpirits}} {{ 'categories.bottles' | translate }}</h6>
                        <div class="images">
                            @for (spirit of category.spirits; track spirit.uuid) {
                                <img [src]="spirit?.catalogSpirit!.image | spiritImage" alt="">
                            }
                        </div>
                    </article>
                }

            }
        </div>
    </section>
</main>