import { Component } from '@angular/core';

@Component({
  selector: 'sustainability-icon',
  standalone: true,
  imports: [],
  templateUrl: './sustainability.component.html',
  styleUrl: './sustainability.component.scss'
})
export class SustainabilityIcon {

}
