import { Component } from '@angular/core';

@Component({
  selector: 'type-icon',
  standalone: true,
  imports: [],
  templateUrl: './type.component.html',
  styleUrl: './type.component.scss'
})
export class TypeIcon {}
