import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentLang: string = 'es';

  constructor() {}

  getCurrentLang() {
    return this.currentLang;
  }

  setCurrentLang(lang: string) {
    this.currentLang = lang;
  }
}
