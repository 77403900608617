<div class="search" [class.active]="search.length > 0">
    @if (section == SECTIONS.MENU) {
        <mat-form-field appearance="outline">
            <mat-icon matPrefix class="black-medium-text">search</mat-icon>
            <input #searchInput matInput (keyup)="applySearch($event)" placeholder="{{ 'menu.search' | translate}}" [(ngModel)]="search" autocomplete="off">
        </mat-form-field>
        @if (search.length > 0) {
            <button mat-button matSuffix (click)="clearSearch();">{{ 'menu.cancel_search' | translate }}</button>
        }
    }
</div>