import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { LocalService } from '../../../core/services/local.service';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalConstants } from '../../../core/global-constants';
import { MenuTranslationPipe } from '../../../core/pipes/menuTranslation';

const pipes = [
  MenuTranslationPipe
]

@Component({
  selector: 'app-section-switch',
  standalone: true,
  imports: [...pipes, TranslateModule],
  templateUrl: './section-switch.component.html',
  styleUrl: './section-switch.component.scss'
})
export class SectionSwitchComponent {

  SECTIONS = GlobalConstants.SECTIONS;

  private local = inject(LocalService)

  @Input() menu: any;
  @Input() section: string = '';

  @Output() update = new EventEmitter<string>();

  hasRecommended = false;
  showRecommended = false;
  showSelection = false;

  constructor() {}

  ngOnInit(): void {
    this.hasRecommended = this.menu.hasRecommended ?? true;
    this.showRecommended = this.menu.sections?.includes('recommended') ?? true;
    this.showSelection = this.menu.sections?.includes('selection') ?? true;
  }

  changeSection(section: string) {
    this.local.saveData('section', section);
    this.update.emit(section);
  }

}
