import { Component } from '@angular/core';

@Component({
  selector: 'flavour-icon',
  standalone: true,
  imports: [],
  templateUrl: './flavour.component.html',
  styleUrl: './flavour.component.scss'
})
export class FlavourIcon {

}
