import { Component, Inject, WritableSignal, inject, signal } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { SelectedFilters } from '../../../../core/models/selectedFilters.model';
import { MatIconModule } from '@angular/material/icon';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslateModule } from '@ngx-translate/core';
import { PriceVariantComponent } from '../../filters/price-variant/price-variant.component';
import { MatButtonModule } from '@angular/material/button';
import { CategoryFilter } from '../../../../core/models/categoryFilter.model';
import { DataIconComponent } from '../../../../shared/components/data-icon/data-icon.component';
import { CatalogTranslationPipe } from '../../../../core/pipes/catalogTranslation';
import { Filters } from '../../../../core/models/filters.model';

const materialModules = [
  MatIconModule,
  MatButtonModule,
  MatBottomSheetModule
]

const components = [
  DataIconComponent
]

const pipes = [
  CatalogTranslationPipe
]

@Component({
  selector: 'app-mobile-filters',
  standalone: true,
  imports: [...materialModules, ...components, ...pipes, TranslateModule],
  templateUrl: './mobile-filters.component.html',
  styleUrl: './mobile-filters.component.scss'
})
export class MobileFiltersComponent {

  private api = inject(ApiService);

  categoryFilters: WritableSignal<CategoryFilter[]|null> = signal(null);
  allFilters: WritableSignal<Filters|null> = signal(null);

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<MobileFiltersComponent>
  ) {}

  ngOnInit() {
    this.categoryFilters.set(this.data.categoryFilters);
    this.allFilters.set(this.data.allFilters);
    this.getSelectedFiltersCount();
  }

  openFilter(categoryFilter: CategoryFilter) {
    this._bottomSheetRef.dismiss(categoryFilter);
  }

  openPriceVariantFilter() {
    this._bottomSheetRef.dismiss(PriceVariantComponent);
  }

  getSelectedFiltersCount() {
    const selectedFilters = this.api.getSelectedFilters();
    this.categoryFilters()?.forEach(categoryFilter => {
      const key = categoryFilter.filter.name;
      categoryFilter.selectedCount = selectedFilters[key as keyof SelectedFilters].length;
    });
  }

  dismiss() {
    this._bottomSheetRef.dismiss();
  }
}
