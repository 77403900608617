import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'menuTranslation'
})
export class MenuTranslationPipe implements PipeTransform {

    private translateService = inject(TranslateService)

    transform(menu: any | undefined, variable: string): string|null {
        if (!menu)
            return '';

        const menuTranslation = menu.translations?.find((x: any) => x.locale.code == (this.translateService.currentLang ?? 'es'));
        if (menuTranslation && menuTranslation[variable])
            return menuTranslation[variable];

        return null;
    }
}