import { Component, Input, inject } from '@angular/core';
import { Spirit } from '../../../../core/models/spirit.model';
import { GridItemComponent } from '../grid-item/grid-item.component';
import { GlobalConstants } from '../../../../core/global-constants';
import { Router } from '@angular/router';
import { LocalService } from '../../../../core/services/local.service';

@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [GridItemComponent],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss'
})
export class GridComponent {

  @Input() uuid: string = '';
  @Input() spirits: Spirit[] | null = []
  @Input() menu: any;
  @Input() section: string = '';
  

  private local = inject(LocalService);

  SECTIONS = GlobalConstants.SECTIONS;

  constructor(
    private router: Router
  ) {}

  navigate(spirit: Spirit) {
    this.local.setDataWithExpiration('detailSpirit', JSON.stringify(spirit), 0, 1);
    this.router.navigate([`/${this.uuid}/menu/${spirit.uuid}`]);
  }
}
