<header>
    <div>
        <mat-icon class="text-white">tune</mat-icon>
        <span class="text-white">{{ 'menu.filters' | translate }}</span>
    </div>
    <button mat-icon-button class="text-light" (click)="dismiss()"><mat-icon>close</mat-icon></button>
</header>

<main>
    @defer(when categoryFilters() && allFilters()) {

        @for (categoryFilter of categoryFilters(); track categoryFilter.uuid) {

            @if (allFilters()?.[categoryFilter?.filter?.name ?? ''] && allFilters()?.[categoryFilter!.filter!.name]!.length > 0) {

                <div class="filter" (click)="openFilter(categoryFilter)">
                    <app-data-icon class="text-light icon" [type]="categoryFilter?.filter?.name"></app-data-icon>
                    <span class="name">{{ categoryFilter | catalogTranslation }}</span>
        
                    @if (categoryFilter?.selectedCount) {
                        <span class="count">{{ categoryFilter?.selectedCount ?? 0 }}</span>
                    }
                </div>

            }
    
        }

    } @placeholder {

        <div class="filter placeholder-wave">
            <div class="placeholder"></div>
            <h5 class="placeholder"></h5>
        </div>
        <div class="filter placeholder-wave">
            <div class="placeholder"></div>
            <h5 class="placeholder"></h5>
        </div>

    }
</main>