import { Component } from '@angular/core';

@Component({
  selector: 'food-pairings-icon',
  standalone: true,
  imports: [],
  templateUrl: './food-pairings.component.html',
  styleUrl: './food-pairings.component.scss'
})
export class FoodPairingsIcon {

}
