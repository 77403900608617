import { Component, Input, OnInit } from '@angular/core';
import { TypeIcon } from '../../icons/type/type.component';
import { CountryIcon } from '../../icons/country/country.component';
import { RegionIcon } from '../../icons/region/region.component';
import { BrandIcon } from '../../icons/brand/brand.component';
import { VintageIcon } from '../../icons/vintage/vintage.component';
import { PriceIcon } from '../../icons/price/price.component';
import { AgeIcon } from '../../icons/age/age.component';
import { BodyIcon } from '../../icons/body/body.component';
import { BottlerIcon } from '../../icons/bottler/bottler.component';
import { BottlingDateIcon } from '../../icons/bottling-date/bottling-date.component';
import { BottlingStatusIcon } from '../../icons/bottling-status/bottling-status.component';
import { CapacityIcon } from '../../icons/capacity/capacity.component';
import { CharactersIcon } from '../../icons/characters/characters.component';
import { FlavourIcon } from '../../icons/flavour/flavour.component';
import { FoodPairingsIcon } from '../../icons/food-pairings/food-pairings.component';
import { LimitedEditionIcon } from '../../icons/limited-edition/limited-edition.component';
import { NumberOfBottlesIcon } from '../../icons/number-of-bottles/number-of-bottles.component';
import { RichnessIcon } from '../../icons/richness/richness.component';
import { SeriesIcon } from '../../icons/series/series.component';
import { SmokeIcon } from '../../icons/smoke/smoke.component';
import { SweetnessComponent } from '../../icons/sweetness/sweetness.component';
import { VolumeIcon } from '../../icons/volume/volume.component';
import { CaskNumberIcon } from '../../icons/cask-number/cask-number.component';
import { CaskTypeIcon } from '../../icons/cask-type/cask-type.component';
import { CertificationIcon } from '../../icons/certification/certification.component';
import { ChillFilterIcon } from '../../icons/chill-filter/chill-filter.component';
import { ColouringIcon } from '../../icons/colouring/colouring.component';
import { GrapesIcon } from '../../icons/grapes/grapes.component';
import { IngTypeIcon } from '../../icons/ing-type/ing-type.component';
import { NoAddsIcon } from '../../icons/no-adds/no-adds.component';
import { StyleIcon } from '../../icons/style/style.component';
import { SustainabilityIcon } from '../../icons/sustainability/sustainability.component';
import { DescriptionIcon } from '../../icons/description/description.component';
import { CategoryIcon } from '../../icons/category/category.component';
import { TastingNotesIcon } from '../../icons/tasting-notes/tasting-notes.component';
import { ElaborationIcon } from '../../icons/elaboration/elaboration.component';
import { IngredientsIcon } from '../../icons/ingredients/ingredients.component';

const icons = [
  AgeIcon,
  BodyIcon,
  BottlerIcon,
  BottlingDateIcon,
  BottlingStatusIcon,
  BrandIcon,
  CapacityIcon,
  CaskNumberIcon,
  CaskTypeIcon,
  CategoryIcon,
  CertificationIcon,
  CharactersIcon,
  ChillFilterIcon,
  ColouringIcon,
  CountryIcon,
  DescriptionIcon,
  FlavourIcon,
  FoodPairingsIcon,
  GrapesIcon,
  IngTypeIcon,
  LimitedEditionIcon,
  NoAddsIcon,
  NumberOfBottlesIcon,
  PriceIcon,
  RegionIcon,
  RichnessIcon,
  SeriesIcon,
  SmokeIcon,
  StyleIcon,
  SustainabilityIcon,
  SweetnessComponent,
  TastingNotesIcon,
  TypeIcon,
  VintageIcon,
  VolumeIcon,
  ElaborationIcon,
  IngredientsIcon
]

@Component({
  selector: 'app-data-icon',
  standalone: true,
  imports: [...icons],
  templateUrl: './data-icon.component.html',
  styleUrl: './data-icon.component.scss'
})
export class DataIconComponent {

  @Input() type?: string = '';

}
