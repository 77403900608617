@if (
    data &&
    (valueType() != 'Array' || (valueType() == 'Array' && value().length > 0)) &&
    (valueType() != 'Spirit' || (valueType() == 'Spirit' && (value() | spiritTranslation:key())))
) {
    <div class="characteristic">

        <app-data-icon class="text-light icon" [type]="key()"></app-data-icon>
        
        @if (valueType() == 'string') {
            <p class="text-light">{{ "spirit." + key() | translate }}: <b>{{ value() }}</b></p>
        }
        
        @if (valueType() == 'number') {
            <p class="text-light">{{ "spirit." + key() | translate }}: <b>{{ value() }}</b></p>
        }

        @if (valueType() == 'Spirit') {
            <p class="text-light"><b>{{ "spirit." + key() | translate }}</b><br>{{ value() | spiritTranslation:key() | lineBreak }}</p>
        }
        
        @if (valueType() == 'Textarea') {
            <p class="text-light"><b>{{ "spirit." + key() | translate }}</b><br>{{ value() | lineBreak }}</p>
        }
        
        @if (valueType() == 'Object') {
            <p class="text-light">{{ "spirit." + key() | translate }}: <b>{{ value() | catalogTranslation }}</b></p>
        }
        
        @if (valueType() == 'boolean') {
            <p class="text-light">{{ "spirit." + key() | translate }}: <b>{{ ( value() ? 'spirit.si' : 'spirit.no' ) | translate }}</b></p>
        }
        
        @if (valueType() == 'Circles') {
            <div class="circles">
                <p class="text-light">{{ "spirit." + key() | translate }}</p>
                <div class="values">
                    <div class="value" [class.background-primary]="1 <= value()" [class.shadow-primary]="1 <= value()"></div>
                    <div class="value" [class.background-primary]="2 <= value()" [class.shadow-primary]="2 <= value()"></div>
                    <div class="value" [class.background-primary]="3 <= value()" [class.shadow-primary]="3 <= value()"></div>
                    <div class="value" [class.background-primary]="4 <= value()" [class.shadow-primary]="4 <= value()"></div>
                    <div class="value" [class.background-primary]="5 <= value()" [class.shadow-primary]="5 <= value()"></div>
                </div>
            </div>
        }
        
        @if (valueType() == 'Array') {
        
            <p class="text-light">{{ "spirit." + key() | translate }}: 
                @for (item of value(); track item.key) {
                    <b>{{ (item | catalogTranslation) ?? item }}</b>, 
                }
            </p>
        
        }

    </div>
}


