import { Component, EventEmitter, Input, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { Spirit } from '../../../core/models/spirit.model';
import { PriceComponent } from '../../../shared/components/price/price.component';
import { SpiritImagePipe } from '../../../core/pipes/spiritImage';
import { KeyValuePipe } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpiritDetailCharacteristicComponent } from './spirit-detail-characteristic/spirit-detail-characteristic.component';
import { LocalService } from '../../../core/services/local.service';
import { SpiritTranslationPipe } from '../../../core/pipes/spiritTranslation';
import { CatalogTranslationPipe } from '../../../core/pipes/catalogTranslation';
import { CatalogSpirit } from '../../../core/models/catalogSpirit.model';

const materialModules = [
  MatIconModule,
  MatButtonModule
]

const components = [
  SpiritDetailCharacteristicComponent
]

const pipes = [
  SpiritTranslationPipe,
  CatalogTranslationPipe
]

@Component({
  selector: 'app-spirit-detail',
  standalone: true,
  imports: [
    ...materialModules, ...components, ...pipes, PriceComponent, SpiritImagePipe, KeyValuePipe, TranslateModule
  ],
  templateUrl: './spirit-detail.component.html',
  styleUrl: './spirit-detail.component.scss'
})
export class SpiritDetailComponent implements OnInit {

  private api = inject(ApiService);
  private local = inject(LocalService);

  @Input() spiritSlugname: string = '';

  menu: WritableSignal<any> = signal(null);
  spirit: WritableSignal<Spirit | null> = signal(null);
  catalogSpirit: WritableSignal<CatalogSpirit | null> = signal(null);

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const detailSpirit = this.local.getDataWithExpiration('detailSpirit');
    if (detailSpirit) {
      this.spirit.set(JSON.parse(detailSpirit));
      this.local.removeData('detailSpirit');
    }
    this.getSpirit();
    this.getMenu();
  }

  getMenu() {
    this.api.getMenu().subscribe(
      data => {
        this.menu.set(data);
      }
    );
  }

  getSpirit() {
    this.api.getCompanySpirit(this.spiritSlugname).subscribe(
      data => {
        this.spirit.set(data);
        this.catalogSpirit.set(data.catalogSpirit);
      }
    );
  }

  goBack() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  favorite(spirit: Spirit) {
    spirit.fav = !spirit.fav;
    this.local.manageFavorites(spirit);
    this.local.saveData('favUpdatedSpirit', JSON.stringify(spirit));
  }
}
