import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-placeholder',
  standalone: true,
  imports: [],
  templateUrl: './carousel-placeholder.component.html',
  styleUrl: './carousel-placeholder.component.scss'
})
export class CarouselPlaceholderComponent {

  items: number[] = [1,2,3,4,5,6,7];
}
