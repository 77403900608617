import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'catalogTranslation'
})
export class CatalogTranslationPipe implements PipeTransform {

    private translateService = inject(TranslateService)

    transform(translatable: any | undefined): string {
        if (!translatable)
            return '';

        const translation = translatable.translations?.find((x: any) => x.locale.code == (this.translateService.currentLang ?? 'es'));

        if (translation)
            return translation.name;

        return translatable.name ?? '';
    }
}