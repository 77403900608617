import { Component, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IntermediateFilterComponent } from '../intermediate-filter/intermediate-filter.component';
import { IntermediateFilter } from '../../../core/models/intermediateFilter.model';
import { ApiService } from '../../../core/services/api.service';
import { CategoryFilter } from '../../../core/models/categoryFilter.model';
import { Filters } from '../../../core/models/filters.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Filter } from '../../../core/models/filter.model';

const materialModules = [
  MatButtonModule,
  MatIconModule
]

const components = [
  IntermediateFilterComponent
];

@Component({
  selector: 'app-intermediate-filters',
  standalone: true,
  imports: [TranslateModule, ...materialModules, ...components],
  templateUrl: './intermediate-filters.component.html',
  styleUrl: './intermediate-filters.component.scss'
})
export class IntermediateFiltersComponent implements OnInit {

  private translate = inject(TranslateService);
  private api = inject(ApiService);

  selectedIntermediateFilterId: WritableSignal<number> = signal(-1);
  selectedFiltersCount: WritableSignal<number> = signal(0);

  intermediateFilters: IntermediateFilter[] = [];
  selectedCategory: WritableSignal<Filter|null> = signal(null); 
  categoryFilters: WritableSignal<CategoryFilter[]|null> = signal(null);
  allFilters: WritableSignal<Filters|null> = signal(null);

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setIntermediateFilters();
    this.getSelectedCategory();
    this.getCategoryFilters();
  }

  setIntermediateFilters() {
    this.intermediateFilters = [
      {
        id: 1,
        label: this.translate.instant('filters.type'),
        photo: 'assets/images/intermediate-filters/type.jpg',
        type: 'type',
        subfilter: '',
        uuid: ''
      },
      {
        id: 2,
        label: this.translate.instant('filters.country') + ' / ' + this.translate.instant('filters.region'),
        photo: 'assets/images/intermediate-filters/country.jpg',
        type: 'country',
        subfilter: '',
        uuid: ''
      },
      {
        id: 3,
        label: this.translate.instant('filters.brand'),
        photo: 'assets/images/intermediate-filters/brand.jpg',
        type: 'brand',
        subfilter: '',
        uuid: ''
      },
    ]
  }

  getSelectedCategory() {
    const selected = this.api.getSelectedFilters();
    const category = selected.category[0];
    this.selectedCategory.set(category);
  }

  openIntermediateFilter(id: number) {
    this.selectedIntermediateFilterId.update(() => id);
  }

  closeIntermediateFilter() {
    this.selectedIntermediateFilterId.update(() => -1);
  }

  getCategoryFilters() {
    this.api.getCategoryFilters().subscribe(
      data => {
        this.setCategoryFiltersUuidToIntermediateFilters(data);
        if (data.length > 0) {
          this.getAllFilterData(data[0].uuid);
        }
      }
    );
  }

  getAllFilterData(filterUuid: string) {
    this.api.getAllFilterData(filterUuid).subscribe(
      data => {
        this.allFilters.set(data);
      }
    );
  }

  getSelectedFiltersCount() {
    const selectedFilters = this.api.getSelectedFilters();
    let selectedFiltersCount = 0;
    this.intermediateFilters.forEach(intermediateFilter => {
      const key = intermediateFilter.type;
      selectedFiltersCount += selectedFilters[key].length;
    });
    this.selectedFiltersCount.set(selectedFiltersCount);
  }

  setCategoryFiltersUuidToIntermediateFilters(categoryFilters: CategoryFilter[]) {
    this.intermediateFilters.forEach(intermediateFilter => {
      const categoryFilter = categoryFilters.find(x => x.filter.name == intermediateFilter.type);
      intermediateFilter.uuid = categoryFilter?.uuid ?? '';
    });
  }

  goBack() {
    this.router.navigate(['../categories'], {relativeTo: this.route});
  }

  navigateToMenu() {
    this.router.navigate(['../menu'], {relativeTo: this.route});
  }

}
