<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6206_904)">
    <path d="M12 24C10.35 24 8.79583 23.6875 7.3375 23.0625C5.87917 22.4375 4.60417 21.5792 3.5125 20.4875C2.42083 19.3958 1.5625 18.1208 0.9375 16.6625C0.3125 15.2042 0 13.65 0 12H2C2 13.1833 2.2 14.3167 2.6 15.4C3 16.4833 3.55417 17.4583 4.2625 18.325C4.97083 19.1917 5.81667 19.9292 6.8 20.5375C7.78333 21.1458 8.85833 21.575 10.025 21.825L7.4 19.2L8.8 17.8L14.7 23.7C14.2667 23.8 13.8208 23.875 13.3625 23.925C12.9042 23.975 12.45 24 12 24ZM22 12C22 10.8167 21.8 9.68333 21.4 8.6C21 7.51667 20.4458 6.54167 19.7375 5.675C19.0292 4.80833 18.1833 4.07083 17.2 3.4625C16.2167 2.85417 15.1417 2.425 13.975 2.175L16.6 4.8L15.2 6.2L9.3 0.3C9.73333 0.2 10.1792 0.125 10.6375 0.075C11.0958 0.025 11.55 0 12 0C13.65 0 15.2042 0.3125 16.6625 0.9375C18.1208 1.5625 19.3958 2.42083 20.4875 3.5125C21.5792 4.60417 22.4375 5.87917 23.0625 7.3375C23.6875 8.79583 24 10.35 24 12H22Z" fill="currentColor"/>
    <path d="M9.36795 15.9442C9.72139 16.1514 10.084 16.3007 10.4557 16.3921C10.8274 16.4835 11.2083 16.5292 11.5983 16.5292C12.1589 16.5292 12.7134 16.4165 13.2619 16.191C13.8103 15.9655 14.3344 15.603 14.834 15.1033C15.0534 14.8839 15.2758 14.5762 15.5013 14.1801C15.7268 13.784 15.9218 13.266 16.0863 12.6262C16.2508 11.9863 16.3758 11.2124 16.4611 10.3044C16.5464 9.39648 16.5586 8.31484 16.4976 7.05952C15.9005 7.03515 15.2271 7.02601 14.4776 7.0321C13.728 7.0382 12.9815 7.09609 12.2381 7.20577C11.4947 7.31546 10.7878 7.49218 10.1175 7.73593C9.44717 7.97968 8.89874 8.31484 8.47217 8.7414C7.92374 9.28984 7.54592 9.83218 7.33874 10.3684C7.13155 10.9047 7.02795 11.4226 7.02795 11.9223C7.02795 12.6414 7.16506 13.2721 7.43928 13.8144C7.7135 14.3568 7.9542 14.7376 8.16139 14.957C8.67327 13.982 9.34967 13.0466 10.1906 12.1509C11.0315 11.2551 12.0126 10.5208 13.1339 9.94796C12.2564 10.7158 11.4916 11.5841 10.8396 12.553C10.1876 13.5219 9.69702 14.6523 9.36795 15.9442Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_6206_904">
    <rect width="24" height="24" fill="none"/>
    </clipPath>
    </defs>
    </svg>
    