@if (menu) {
    <div id="home">
        @if (menu.user.activeModules.includes('coverPage') && menu.coverImage) {
            <div class="cover-page">
                <img class="background" [src]="menu.coverImage | coverImage" />
                <img class="cover-page-logo" [src]="menu.user.photo | userImage"/>
                <div class="cover-page-buttons">
                    <div class="cover-page-buttons-centered">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'home.language' | translate }}</mat-label>
                            <mat-select (selectionChange)="switchLanguage()" [(value)]="selectedLanguage">
                                @for (locale of menu.locales; track locale) {
                                    <mat-option [value]="locale.locale.code">
                                        {{ getLanguageName(locale.locale.code) }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="cover-page-buttons-centered">
                        <button type="button" (click)="navigate();">{{ 'home.view_menu' | translate }}</button>
                    </div>
                    @if (menu.user.winerimUrl) {
                        <div class="container-buttons-centered">
                            <button type="button" (click)="goToWinerim();">
                                <img class="winerim-logo" src="/assets/images/logo-winerim.png"/>
                                <span>{{ 'home.winerim' | translate }}</span>
                            </button>
                        </div>
                    }
                </div>
            </div>
        } @else {
            <div class="container">
                <img class="logo" [src]="menu.user.photo | userImage"/>
                <span>{{ menu.user.name }}</span>
                @if (menu.locales.length > 1) {
                    <div class="container-buttons">
                        <div class="container-buttons-centered">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'home.language' | translate }}</mat-label>
                                <mat-select (selectionChange)="switchLanguage()" [(value)]="selectedLanguage">
                                    @for (locale of menu.locales; track locale) {
                                        <mat-option [value]="locale.locale.code">
                                            {{ getLanguageName(locale.locale.code) }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="container-buttons-centered">
                            <button type="button" (click)="navigate();">{{ 'home.view_menu' | translate }}</button>
                        </div>
                        @if (menu.user.winerimUrl) {
                            <div class="container-buttons-centered">
                                <button type="button" (click)="goToWinerim();">
                                    <img class="winerim-logo" src="/assets/images/logo-winerim.png"/>
                                    <span>{{ 'home.winerim' | translate }}</span>
                                </button>
                            </div>
                        }
                    </div>
                }@else if (menu.locales.length == 1) {
                    <div class="languages">
                        <span class="one-language" (click)="navigate()"><mat-icon>info</mat-icon>{{ 'home.view_menu' | translate }}</span>
                    </div>
                }
            </div>
        }
        <div class="absolute-bottom">
            <img class="spiritsrim-logo" src="/assets/images/logo.png"/>
            <button mat-button color="primary" (click)="resetLocalStorage()">Reset</button>
        </div>
    </div>
}
