import { Component } from '@angular/core';

@Component({
  selector: 'vaso-icon',
  standalone: true,
  imports: [],
  templateUrl: './vaso.component.html',
  styleUrl: './vaso.component.scss'
})
export class VasoIcon {}
