import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalConstants } from '../../../../core/global-constants';
import { MenuTranslationPipe } from '../../../../core/pipes/menuTranslation';

const pipes = [
  MenuTranslationPipe
]

@Component({
  selector: 'app-menu-title',
  standalone: true,
  imports: [...pipes, TranslateModule],
  templateUrl: './menu-title.component.html',
  styleUrl: './menu-title.component.scss'
})
export class MenuTitleComponent {

  SECTIONS = GlobalConstants.SECTIONS;
  
  @Input() menu: any;
  @Input() section: string = '';

}
