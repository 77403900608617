import { Component } from '@angular/core';

@Component({
  selector: 'colouring-icon',
  standalone: true,
  imports: [],
  templateUrl: './colouring.component.html',
  styleUrl: './colouring.component.scss'
})
export class ColouringIcon {

}
