import { Component, EventEmitter, Input, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SelectedFilters } from '../../../../core/models/selectedFilters.model';
import { ApiService } from '../../../../core/services/api.service';
import { KeyValuePipe } from '@angular/common';
import { Filter } from '../../../../core/models/filter.model';
import { DataIconComponent } from '../../../../shared/components/data-icon/data-icon.component';

const materialModules = [
  MatIconModule,
  MatButtonModule
]

const components = [
  DataIconComponent
]

@Component({
  selector: 'app-selected-filters',
  standalone: true,
  imports: [KeyValuePipe, ...materialModules, ...components],
  templateUrl: './selected-filters.component.html',
  styleUrl: './selected-filters.component.scss'
})
export class SelectedFiltersComponent implements OnInit {

  @Output() update = new EventEmitter();

  private api = inject(ApiService);

  selectedFilters: WritableSignal<SelectedFilters|null> = signal(null);
  anySelectedFilter: WritableSignal<boolean> = signal(false);

  ngOnInit(): void {
    this.updateSelectedFilters();
  }

  updateSelectedFilters() {
    this.selectedFilters.set(this.api.getSelectedFilters());
    this.anySelectedFilter.set(this.checkSelectedFilters());
  }

  checkSelectedFilters(): boolean {
    if (!this.selectedFilters()) return false;

    for (let key in this.selectedFilters()!) {
      if (this.selectedFilters()![key as keyof SelectedFilters].length > 0 && key != 'category') {
        return true;
      }
    }

    return false;
  }

  deleteAllSelectedFilters() {
    for (let key in this.selectedFilters()!) {
      if (key != 'category') {
        this.selectedFilters()![key as keyof SelectedFilters] = [];
      }
    }
    this.api.setSelectedFilters(this.selectedFilters()!);
    this.anySelectedFilter.set(false);
    this.updateSpirits();
  }

  deleteSelectedFilter(key: keyof SelectedFilters, uuid: string) {
    const filterToDelete = this.selectedFilters()![key].find((x: Filter) => x.uuid == uuid);
    if (filterToDelete) {
      const index = this.selectedFilters()![key].indexOf(filterToDelete);
      this.selectedFilters()![key].splice(index, 1);
    }
    this.api.setSelectedFilters(this.selectedFilters()!);
    this.anySelectedFilter.set(this.checkSelectedFilters());
    this.updateSpirits();
  }
  
  updateSpirits() {
    this.update.emit();
  }

}
