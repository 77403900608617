<main>
    <header>
        <div>
            <button mat-flat-button (click)="goBack()">
                <mat-icon>chevron_left</mat-icon>
                Atrás
            </button>
            <h1>{{ selectedCategory()?.name }}</h1>
        </div>
        <button mat-flat-button (click)="navigateToMenu()">
            Ver resultados
            <mat-icon iconPositionEnd>chevron_right</mat-icon>
        </button>
    </header>

    @for (intermediateFilter of intermediateFilters; track intermediateFilter.id) {

        <app-intermediate-filter
            [class.opened]="selectedIntermediateFilterId() == intermediateFilter.id"
            [intermediateFilter]="intermediateFilter"
            [selectedIntermediateFilterId]="selectedIntermediateFilterId()"
            [allFilters]="allFilters()"
            (openFilterEvent)="openIntermediateFilter($event)"
            (closeFilterEvent)="closeIntermediateFilter()"
        ></app-intermediate-filter>

    }
</main>