import { Component } from '@angular/core';

@Component({
  selector: 'chill-filter-icon',
  standalone: true,
  imports: [],
  templateUrl: './chill-filter.component.html',
  styleUrl: './chill-filter.component.scss'
})
export class ChillFilterIcon {

}
