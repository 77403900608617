import { Component, EventEmitter, Input, NgZone, OnInit, Output, inject } from '@angular/core';
import { Spirit } from '../../../../core/models/spirit.model';
import { CarouselItemComponent } from '../carousel-item/carousel-item.component';
import { CarouselActiveItemComponent } from '../carousel-active-item/carousel-active-item.component';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { SwiperOptions, FreeMode, Virtual, Keyboard, Mousewheel, EffectCoverflow } from 'swiper';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../../../core/global-constants';
import { LocalService } from '../../../../core/services/local.service';
import { BackgroundImagePipe } from '../../../../core/pipes/backgroundImage';

SwiperCore.use([FreeMode, Virtual, Keyboard, Mousewheel, EffectCoverflow]);

const pipes = [
  BackgroundImagePipe
]

const components = [
  CarouselItemComponent,
  CarouselActiveItemComponent
]

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [...components, ...pipes, SwiperModule],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss'
})
export class CarouselComponent implements OnInit {

  @Input() uuid: string = '';
  @Input() spirits: Spirit[] | null = null;
  @Input() menu: any;
  @Input() section: string = '';
  @Output() getSpirits = new EventEmitter();

  private local = inject(LocalService);

  SECTIONS = GlobalConstants.SECTIONS;

  activeSpirit: Spirit | null = null;

  swiperOptions: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 50,
    centeredSlides: true,
    loop: false,
    breakpointsBase: 'window',
    effect: 'coverflow',
    slideToClickedSlide: true,
    observer: true,
    runCallbacksOnInit: true,
    mousewheel: true,
    keyboard: {
      enabled: true
    },
    virtual: {
      enabled: true,
      addSlidesAfter: 3,
      addSlidesBefore: 3,
      cache: false
    },
    freeMode: {
      enabled: true,
      momentum: true,
      sticky: true
    },
    coverflowEffect: {
      rotate: 0,
      modifier: 1,
      slideShadows: false,
      scale: 1
    },
    initialSlide: 0,
    breakpoints: {
      640: {
        slidesPerView: 4,
        spaceBetween: 50
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50
      },
      1300: {
        slidesPerView: 6,
        spaceBetween: 70
      },
      2048: {
        slidesPerView: 8,
        spaceBetween: 70
      }
    }
  };

  constructor(
    private router: Router,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.activeSpirit = this.spirits![0] ?? null;
  }

  onSlide(event: any) {
    this.zone.run(() => {
      setTimeout(() => {
        const index = event[0].activeIndex;
        const spirit = this.spirits![index];
        this.activeSpirit = spirit;

        if (this.spirits!.length - 4 <= index && this.section != this.SECTIONS.FAVORITES) {
          this.getSpirits.emit();
        }
      }, 200);
    });
  }

  navigate(spirit: Spirit) {
    if (spirit.uuid == this.activeSpirit!.uuid) {
      this.local.setDataWithExpiration('detailSpirit', JSON.stringify(spirit), 0, 1);
      this.router.navigate([`/${this.uuid}/menu/${spirit.uuid}`]);
    }
  }
}