import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, from, map, throwError } from 'rxjs';
import { Filter } from '../models/filter.model';
import { Spirit } from '../models/spirit.model';
import { SelectedFilters } from '../models/selectedFilters.model';
import { Category } from '../models/category.model';
import { GlobalConstants } from '../global-constants';
import { LanguageService } from './language.service';
import { CapacitorHttp } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  SECTIONS = GlobalConstants.SECTIONS;

  private url: string = environment.api;
  private http = inject(HttpClient);
  private languageService = inject(LanguageService);

  private countries: WritableSignal<any> = signal(null);
  
  private uuid = signal('');
  private filters = signal(new SelectedFilters());
  private section = signal('');
  private search = signal('');
  private lang = signal('es');

  setUuid(uuid: string) {
    this.uuid.update(() => uuid);
  }

  setCountries() {
    this.countries.set(new Intl.DisplayNames([this.lang()], { type: 'region' }));
  }

  getMenu() {
    const url = this.url + '/api/menu/' + this.uuid();
    return this.http.get<any>(url);
  }

  getCompanyCategories() {
    const url = this.url + '/api/menu/' + this.uuid() + '/categories';
    return this.http.get<any>(url);
  }

  getCoverPage() {
    const url = this.url + '/api/v1/restaurant/' + this.uuid() + '/coverPage';
    return this.http.get<any>(url);
  }

  getCompanySpirits(page: number, section: string, search: string) {
    const localFavorites = localStorage.getItem(this.uuid() + '_favorites');
    const favorites = localFavorites ? JSON.parse(localFavorites) : [];
    const currentCategory = this.filters().category[0].uuid;
    const categoryFavorites = favorites[currentCategory] ?? [];

    let url = this.url + '/api/menu/' + this.uuid() + '/spirits/?page=' + page + '&active=1';

    if (section == this.SECTIONS.RECOMMENDED) {
      url += `&${section}=1`;
    } else {
      url += `&selection=${section == this.SECTIONS.SELECTION ? 1 : 0}`;
    }

    if (search) {
      url += `&search=${search}`;
    }

    url += this.prepareUrl();

    return this.http.get<any>(url).pipe(
      map(data => {
        data.results.forEach((spirit: Spirit) => {
          const index = categoryFavorites.findIndex((favorite: Spirit) => favorite.uuid == spirit.uuid);
          spirit.fav = index != -1; 
        });
        return data;
      })
    );
  }

  getCompanySpirit(spirit: string) {
    const localFavorites = localStorage.getItem(this.uuid() + '_favorites');
    const favorites = localFavorites ? JSON.parse(localFavorites) : [];
    const currentCategory = this.filters().category[0].uuid;
    const categoryFavorites = favorites[currentCategory] ?? [];

    const url = this.url + '/api/menu/' + this.uuid() + '/spirits/' + spirit;
    return this.http.get<Spirit>(url).pipe(
      map(data => {
        const index = categoryFavorites.findIndex((favorite: Spirit) => favorite.uuid == data.uuid);
        data.fav = index != -1; 
        
        return data;
      })
    );
  }

  getCompanySpiritsGrouped() {
    let url = this.url + '/api/v1/restaurant/' + this.uuid() + '/wines/grouped?page=0';
    url += this.prepareUrl();
    return this.http.get<any>(url);
  }

  getCategorySpirits(category: Category, limit: number) {
    let url = this.url + '/api/menu/' + this.uuid() + '/spirits/?page=1&limit=' + limit + '&active=1';
    url += '&category=' + encodeURIComponent(category.uuid);
    return this.http.get<any>(url);
  }

  getCategoryFilters() {
    const url = this.url + '/api/menu/' + this.uuid() + '/categories/' + this.filters().category[0].uuid + '/filters';
    return this.http.get<any>(url);
  }

  getAllFilters() {
    const url = this.url + '/api/v1/restaurant/' + this.uuid() + '/filters';
    return this.http.get<any>(url);
  }

  getFilterData(filterUuid: string) {
    let url = this.url + '/api/menu/' + this.uuid() + '/categories/' + this.filters().category[0].uuid + '/filters/' + filterUuid + '/data';
    url += this.prepareUrlFilters().replace('&', '?') // solo cambia el primer &;
    return this.http.get<any>(url);
  }

  getAllFilterData(filterUuid: string) {
    let url = this.url + '/api/menu/' + this.uuid() + '/categories/' + this.filters().category[0].uuid + '/filters/' + filterUuid + '/data';
    url += `?category=${this.filters().category[0].uuid}`;
    return this.http.get<any>(url);
  }

  setSelectedFilter(filterType: keyof SelectedFilters, filters: Filter[]) {
    this.filters()[filterType] = filters;
    this.saveFilters();
  }

  setSelectedFilters(filters: SelectedFilters) {
    this.filters.set(filters);
    this.saveFilters();
  }

  getSelectedFilters() {
    return this.filters();
  }

  clearSelectedFilters() {
    this.filters.set(new SelectedFilters());
  }

  getCountryName(country: string) {
    return this.countries().of(country);
  }

  private prepareUrl(): string {
    let url = '';

    if (this.section()) {
      url += `&${this.section()}=1`;
    } else {
      if (this.search()) {
        url += `&text='${this.search()}`
      } else {
        url += this.prepareUrlFilters();
      }
    }

    return url;
  }
  
  private prepareUrlFilters(): string{
    let url = '';

    for (let key  in this.filters()) {
      if (key == 'prices') {
        // if (filters[key].min || filters[key].max) {
        //   url += '&price=' + filters[key].min + ',' + filters[key].max;
        // }
        // if (filters[key].variants && filters[key].variants.length > 0) {
        //   url += '&variant=' + filters[key].variants.toString();
        // }
      } else {
        if (this.filters()[key as keyof SelectedFilters].length > 0) {
          url += '&' + key + '=' + encodeURIComponent(this.filters()[key as keyof SelectedFilters].map(filter => filter.uuid).join(','));
        }
      }
    }

    return url;
  }


  private saveFilters() {
    localStorage.setItem(this.uuid() + '_selectedFilters', JSON.stringify(this.filters()));
  }
}
