import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'lineBreak'
})
export class LineBreakPipe implements PipeTransform {
    transform(text: string): string {
        const outputText = text.replace(/> /g, '\n');
        return outputText;
    }
}