import { Component } from '@angular/core';

@Component({
  selector: 'characters-icon',
  standalone: true,
  imports: [],
  templateUrl: './characters.component.html',
  styleUrl: './characters.component.scss'
})
export class CharactersIcon {

}
