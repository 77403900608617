<article>
    <div class="spirit">
        <div class="fav" [class.fav-active]="spirit.fav" (click)="favorite(spirit); $event.stopPropagation();">
            <img src="assets/images/{{spirit.fav ? 'fav' : 'no-fav'}}.png"/>
        </div>
        <div class="info" (click)="navigate(spirit)">
            <h6>{{ spirit | spiritTranslation:'name' }}</h6>
            <small>{{ spirit | spiritTranslation:'subname' }}</small>
            <small class="text-light">{{ spirit.catalogSpirit.region | catalogTranslation }} {{ spirit.catalogSpirit.type | catalogTranslation }}</small>
        </div>
    </div>
    <div class="prices">
        <small>{{ copa?.price ? copa.price + ' ' + (menu.currency?.symbol ?? '€') : '-' }}</small>
        /
        <small>{{ botella?.price ? botella.price + ' ' + (menu.currency?.symbol ?? '€') : '-' }}</small>
    </div>
</article>