import { Component } from '@angular/core';

@Component({
  selector: 'number-of-bottles-icon',
  standalone: true,
  imports: [],
  templateUrl: './number-of-bottles.component.html',
  styleUrl: './number-of-bottles.component.scss'
})
export class NumberOfBottlesIcon {

}
