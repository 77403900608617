<main [class.opened]="selectedIntermediateFilterId == intermediateFilter.id">

    <aside>
        <div class="close-filter" (click)="closeFilter($event)">
            <mat-icon>close</mat-icon>
            <span matBadge="{{ selectedCount() }}" matBadgeOverlap="false">{{ intermediateFilter.label }}</span>
        </div>
    </aside>

    <section [class.space-between]="search == ''">

        <div class="filter-search">
            <mat-form-field class="example-form-field" appearance="outline">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput type="text" [(ngModel)]="search" placeholder="{{ 'buscar_web' | translate:{'type': intermediateFilter.label.toLocaleLowerCase()} }}">
                @if (search) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
                      <mat-icon>close</mat-icon>
                    </button>
                }
            </mat-form-field>
        </div>

        <div class="filter-overflow">
            <div class="filter-list">
                @for (filter of filterFilters(filters!); track filter.uuid) {
                    <div class="filter-item" [class.has-subfilters]="intermediateFilter.subfilter" [class.opened]="filterOpened == filter.name || search != ''" [class.selected]="isSelected(intermediateFilter.type, filter)" [class.disabled]="filter.count == 0" (click)="selectFilter(filter)">
                        <span>{{ filter.translation ?? filter.name | translate }} <span class="filter-item-count">({{ filter.count ?? 0 }})</span></span>
                        <mat-icon>chevron_right</mat-icon>
                    </div>

                    @if (intermediateFilter.subfilter != '' && (filterOpened == filter.name || search != '')) {
                        <div class="subfilter-list">

                            @for (subfilter of filterSubfilters(filter[intermediateFilter.subfilter]); track subfilter.uuid) {
                                
                                <div class="subfilter-item" [class.selected]="isSelected(intermediateFilter.subfilter, subfilter.name)" [class.disabled]="subfilter.count == 0" (click)="selectSubfilter(subfilter)">
                                    <span>{{ subfilter.translation ?? subfilter.name | translate }} <span class="subfilter-item-count">({{ subfilter.count ?? 0 }})</span></span>
                                    <mat-icon>chevron_right</mat-icon>
                                </div>

                            }

                        </div>
                    }
                }
            </div>
        </div>

    </section>

</main>