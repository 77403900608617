import { GlobalConstants } from "../global-constants";
import { Filter } from "./filter.model"

export interface ISelectedFilters {
  [key: string]: Filter[];
}
  
export class SelectedFilters implements ISelectedFilters {
  [key: string]: Filter[];
  
  constructor() {
    const properties: string[] = [
      ...Object.values(GlobalConstants.ENTITY_FILTERS),
      ...Object.values(GlobalConstants.RELATION_FILTERS)
    ];

    properties.forEach(property => {
      this[property] = [];
    });
  }
}