import { Component } from '@angular/core';

@Component({
  selector: 'bottling-date-icon',
  standalone: true,
  imports: [],
  templateUrl: './bottling-date.component.html',
  styleUrl: './bottling-date.component.scss'
})
export class BottlingDateIcon {

}
