import { Injectable, inject } from '@angular/core';
import { ApiService } from './api.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class CategorySelectedService {

  private api = inject(ApiService);
  private local = inject(LocalService);

  constructor(
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    let uuid = route.params['uuid'];
    this.local.setUuid(uuid);
    this.api.setUuid(uuid);
    this.api.setCountries();
    this.local.setFilters();

    const selectedFilters = this.api.getSelectedFilters();
    if (selectedFilters.category.length == 0) {
      this.router.navigate([uuid + '/categories']);
    }
  }
}